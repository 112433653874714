/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useContext, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";
import UserContext from "./../layout/userContext";
import getWpLetter from "../app/services/getWpLetter";
import getGrievanceLetter from "../app/services/getGrievanceLetter";
import getEt1Letter from "../app/services/getEt1Letter";
import getDsarLetter from "../app/services/getDsarLetter";
import PremiumPopup from "../components/PremiumPopup";
import wpIcon from "../assets/img/WPL.svg";
import newWindow from "../assets/img/New window.svg";
import generateLetter from "../assets/img/generate letter.svg";
import grIcon from "../assets/img/Gavel.svg";
import et1Icon from "../assets/img/ET1.svg";
import reIcon from "../assets/img/RE.svg";
import dataSubjectIcon from "../assets/img/Data subject.svg";
import calculator from "../assets/img/calculate.svg";
import book from "../assets/img/book.svg";
import connect from "../assets/img/Connect.svg";
import more from "../assets/img/More horiz.svg";
import eye from "../assets/img/eye.svg";
import edit from "../assets/img/Edit.svg";
import adviceNote from "../assets/img/AN.svg";
import WSIcon from "../assets/img/WitnessStatement.svg";
import Frame from "../assets/img/Frame.svg";
import { getToken, getLoginType } from "../shared/Helpers";
import getWitnessStatementLetter from "../app/services/getWitnessLetter";
import UpgradePopup from "../components/UpgradePopup";

export default function Dashboard() {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [isOtherPopupVisible, setOtherPopupVisible] = useState(false);
  const [selectedLetter, setSelectedLetter] = useState(null);
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const [openPopup, setOpenPopup] = useState(false);
  const [openUpgradePopup, setOpenUpgradePopup] = useState(false);
  const [letterLink, setLetterLink] = useState("");
  const navigate = useNavigate();
  const userData = useContext(UserContext);
  const IsPremium = userData ? userData.premium : false;
  const authToken = getToken();
  const loginType = getLoginType();

  useEffect(() => {
    if (loginType === "lawyer" && !userData?.premium) {
      const timer = setTimeout(() => {
        setOpenUpgradePopup(true);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [loginType, userData]);

  const getIcon = (letterType) => {
    const icons = {
      "Advice Note": adviceNote,
      "Without Prejudice letter": wpIcon,
      "Grievance Letter": grIcon,
      "ETI Details of Claim": et1Icon,
      "Respond to Employer": reIcon,
      "Data Subject Request": dataSubjectIcon,
      "Settlement Calculator": calculator,
      "Book a paid consultation": book,
      "Witness Statement": WSIcon,
    };
    return icons[letterType] || wpIcon;
  };

  const letterTypes = [
    {
      type: "Advice Note",
      prop: "adviceNote",
      generatedProp: "isAdviceNoteGenerated",
      setOnProp: "adviceNoteSetOn",
      link: "advice-note",
      letterLink: "advice-note",
      letterBody: "",
    },
    {
      type: "Without Prejudice letter",
      prop: "wpLetter",
      checkedProp: "checkedWpLetter",
      generatedProp: "isWpLetterGenerated",
      setOnProp: "wpLetterSetOn",
      link: "wp-letter",
      letterLink: "without-prejudice-letter",
      letterBody: "wpLetterBody",
    },
    {
      type: "Grievance Letter",
      prop: "grievanceLetter",
      checkedProp: "checkedGrievanceLetter",
      generatedProp: "isGrievanceLetterGenerated",
      setOnProp: "grievanceLetterSetOn",
      link: "grievance-letter",
      letterLink: "grievance-letters",
      letterBody: "grievanceLetterBody",
    },
    {
      type: "ETI Details of Claim",
      prop: "et1Letter",
      checkedProp: "checkedEt1Letter",
      generatedProp: "isEt1LetterGenerated",
      setOnProp: "et1LetterSetOn",
      link: "et1-letter",
      letterLink: "et1-details-of-claim",
      letterBody: "et1LetterBody",
    },
    {
      type: "Witness Statement",
      prop: "witnessStatementLetter",
      checkedProp: "checkedWitnessStatementLetter",
      generatedProp: "isWitnessStatementLetterGenerated",
      setOnProp: "witnessStatementLetterSetOn",
      link: "witness-statement-letter",
      letterLink: "witnessStatement-details-of-claim",
      letterBody: "witnessStatementLetterBody",
    },
    {
      type: "Data Subject Request",
      prop: "dsarLetter",
      checkedProp: "checkedDsarLetter",
      generatedProp: "isDsarLetterGenerated",
      setOnProp: "dsarLetterSetOn",
      link: "dsar-letter",
      letterLink: "data-subject-request",
      letterBody: "dsarLetterBody",
    },
    {
      type: "Respond to Employer",
      prop: "respondToEmployer",
      generatedProp: "isRespondToEmployerLetterGenerated",
      setOnProp: "respondToEmployerSetOn",
      link: "response-letter",
      letterLink: "respond-to-employer",
      letterBody: "",
    },
  ];

  const letters = [];

  letterTypes.forEach(
    ({
      type,
      prop,
      checkedProp,
      generatedProp,
      setOnProp,
      link,
      letterBody,
    }) => {
      const effectiveProp =
        userData && checkedProp && userData[checkedProp] ? checkedProp : prop;
      const effectiveStatus =
        checkedProp && userData && userData[checkedProp]
          ? "Checked and completed"
          : "Complete";

      if (
        userData &&
        userData[effectiveProp] &&
        userData[generatedProp] &&
        userData[setOnProp]
      ) {
        const createdDate = new Date(userData[setOnProp]).toLocaleDateString(
          "en-GB",
          {
            day: "2-digit",
            month: "short",
            year: "numeric",
          }
        );
        letters.push({
          letter: type,
          link: link,
          prop: effectiveProp,
          created: createdDate,
          status: effectiveStatus,
          letterBody: letterBody,
          action: "View letter | Update letter",
        });
      }
    }
  );

  let letterDetails = [
    {
      letter: "Advice Note",
      link: "advice-note",
      created: "12 feb 2024",
      status: "Not started",
      action: "Learn more | Generate letter",
      letterLink: "advice-note",
    },
    {
      letter: "Without Prejudice letter",
      link: "wp-letter",
      created: "12 feb 2024",
      status: "Not started",
      action: "Learn more | Generate letter",
      letterLink: "without-prejudice-letter",
    },
    {
      letter: "Grievance Letter",
      link: "grievance-letter",
      created: "12 feb 2024",
      status: "Not started",
      action: "Learn more | Generate letter",
      letterLink: "grievance-letters",
    },
    {
      letter: "ETI Details of Claim",
      link: "et1-letter",
      created: "12 feb 2024",
      status: "Not started",
      action: "Learn more | Generate letter",
      letterLink: "et1-details-of-claim",
    },
    {
      letter: "Witness Statement",
      link: "witness-statement-letter",
      created: "12 feb 2024",
      status: "Not started",
      action: "Learn more | Generate letter",
      letterLink: "witness-statement-letter",
    },
    {
      letter: "Data Subject Request",
      link: "dsar-letter",
      created: "12 feb 2024",
      status: "Not started",
      action: "Learn more | Generate letter",
      letterLink: "data-subject-request",
    },
    {
      letter: "Respond to Employer",
      link: "response-letter",
      created: "12 feb 2024",
      status: "Not started",
      action: "Learn more | Generate letter",
      letterLink: "respond-to-employer",
    },
  ];

  letterDetails = letterDetails.filter(
    (detail) => !letters.some((letter) => letter.letter === detail.letter)
  );

  const showPopup = (event, letter) => {
    event.preventDefault();
    const target = event.currentTarget; // Use currentTarget to ensure you get the span, not inner elements

    const rect = target.getBoundingClientRect();
    const x = rect.left + window.scrollX - 100;
    const y = rect.bottom + window.scrollY + 15; // Position below the icon

    setPopupPosition({ x, y });
    setSelectedLetter(letter);
    setPopupVisible(!isPopupVisible);
  };

  const showOtherPopup = (event, letter) => {
    event.preventDefault();
    const target = event.currentTarget; // Use currentTarget to ensure you get the span, not inner elements

    const rect = target.getBoundingClientRect();
    const x = rect.left + window.scrollX - 100;
    const y = rect.bottom + window.scrollY + 15; // Position below the icon

    setPopupPosition({ x, y });
    setSelectedLetter(letter);
    setOtherPopupVisible(!isOtherPopupVisible);
  };

  const showPremium = (t) => {
    setOpenPopup(true);
    setLetterLink(t);
  };

  const onHide = () => {
    setOpenPopup(false);
  };

  const closePopup = () => {
    setOpenUpgradePopup(false);
  };

  const handlePremiumCheck = (selectedLetter, userData) => {
    if (userData.premium || selectedLetter.link === "advice-note") {
      navigate(`/update-letter?letter=${selectedLetter.link}`, {
        state: {
          lastPage: `/dashboard`,
          userData: userData,
          letterData: userData[selectedLetter.prop],
        },
      });
    } else {
      setOpenPopup(true);
    }
  };

  const viewLetter = (
    letterName,
    letterTitle,
    letterData,
    mainContent,
    userData
  ) => {
    if (userData.premium || letterName === "advice-note") {
      navigate(`/generated-letter`, {
        state: {
          letterName,
          letterTitle,
          letterData,
          mainContent,
          userData,
        },
      });
    } else {
      setOpenPopup(true);
    }
  };

  const checkLetter = async (
    letterName,
    letterTitle,
    mainContent,
    userData
  ) => {
    if (userData.premium) {
      const fetchLetter = async (letterType) => {
        switch (letterType) {
          case "wp-letter":
            return { letter: await getWpLetter() };
          case "grievance-letter":
            return { letter: await getGrievanceLetter() };
          case "et1-letter":
            return { letter: await getEt1Letter() };
          case "witness-statement-letter":
            return { letter: await getWitnessStatementLetter() };
          case "dsar-letter":
            return { letter: await getDsarLetter() };
          default:
            throw new Error("Invalid letter type");
        }
      };

      try {
        const { letter } = await fetchLetter(letterName);
        let letterInfo = {};
        if (letter) {
          letterInfo = letter.data.data.attributes;
        }

        navigate(`/check-letter`, {
          state: {
            letterName: letterName,
            letterTitle: letterTitle,
            letterInfo: letterInfo,
            mainContent: mainContent,
            userData: userData,
          },
        });
      } catch (error) {
        console.error(error);
      }
    } else {
      setOpenPopup(true);
      setLetterLink("check-letter");
    }
  };

  return (
    <>
      <div className="dashboard">
        <h4>Welcome {userData?.name ?? userData?.username}</h4>
        <div className="letters">
          <DataTable value={letters}>
            <Column
              field="letter"
              header="Your letters"
              className="letter-name"
              body={(rowData) => (
                <div
                  className="letter-icon-text"
                  onClick={() =>
                    viewLetter(
                      rowData.link,
                      rowData.letter,
                      userData[rowData.prop],
                      userData[rowData.letterBody],
                      userData
                    )
                  }
                >
                  <img
                    src={getIcon(rowData.letter)}
                    className="letter-icon"
                    alt="icon"
                  />
                  {rowData.letter}
                </div>
              )}
            ></Column>
            <Column
              field="created"
              header="Created"
              className="hide-on-mobile"
            ></Column>
            <Column
              field="status"
              header="Status"
              body={(rowData) => (
                <div className="highlight-text">{rowData.status}</div>
              )}
            ></Column>
            <Column
              field="action"
              header="Action"
              body={(rowData) => (
                <div className="action">
                  <span
                    onClick={(e) => showPopup(e, rowData)}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={more} alt="icon" />
                  </span>
                </div>
              )}
            ></Column>
          </DataTable>
        </div>
        <div className="otherletters">
          <DataTable value={letterDetails}>
            <Column
              field="letter"
              className="letter-name"
              header="Other letters"
              body={(rowData) => (
                <div
                  className="letter-icon-text"
                  onClick={() =>
                    navigate(
                      `/monaco-solicitors-ai-letters/${rowData.letterLink}`
                    )
                  }
                >
                  <img
                    src={getIcon(rowData.letter)}
                    className="letter-icon"
                    alt="icon"
                  />
                  {rowData.letter}
                </div>
              )}
            ></Column>
            <Column
              field="status"
              header="Status"
              body={(rowData) => (
                <div className="highlight-text">{rowData.status}</div>
              )}
            ></Column>
            <Column
              field="action"
              header="Action"
              body={(rowData) => (
                <div className="action">
                  <div
                    className="action hide-on-mobile"
                    style={{ cursor: "pointer" }}
                  >
                    <img src={newWindow} alt="icon" />
                    <span
                      onClick={() =>
                        navigate(
                          `/monaco-solicitors-ai-letters/${rowData.letterLink}`
                        )
                      }
                    >
                      Learn more
                    </span>
                    <img
                      src={generateLetter}
                      alt="icon"
                      className="generate-icon"
                    />
                    <span
                      onClick={() => {
                        if (IsPremium || rowData.link === "advice-note") {
                          navigate(`/data-inputs?letter=${rowData.link}`);
                        } else {
                          showPremium(rowData.link);
                        }
                      }}
                    >
                      Generate letter
                    </span>
                  </div>
                  <span
                    className="hide-on-desktop"
                    onClick={(e) => showOtherPopup(e, rowData)}
                  >
                    <img src={more} alt="icon" />
                  </span>
                </div>
              )}
            ></Column>
          </DataTable>
          {isPopupVisible && (
            <div
              className="popup"
              style={{
                position: "absolute",
                left: `${popupPosition.x}px`,
                top: `${popupPosition.y}px`,
              }}
            >
              <div className="options">
                <img src={eye} alt="icon" />
                <span
                  onClick={() =>
                    viewLetter(
                      selectedLetter.link,
                      selectedLetter.letter,
                      userData[selectedLetter.prop],
                      userData[selectedLetter.letterBody],
                      userData
                    )
                  }
                >
                  View letter
                </span>
              </div>
              {selectedLetter.letter !== "Advice Note" &&
                selectedLetter.letter !== "Respond to Employer" && (
                  <div className="options">
                    <img src={edit} alt="icon" className="check-icon" />
                    <span
                      onClick={() =>
                        checkLetter(
                          selectedLetter.link,
                          selectedLetter.letter,
                          userData[selectedLetter.letterBody],
                          userData
                        )
                      }
                    >
                      Check letter
                    </span>
                  </div>
                )}
              <div className="options">
                <img src={Frame} alt="icon" className="generate-icon" />
                <span
                  onClick={() => handlePremiumCheck(selectedLetter, userData)}
                >
                  Update letter
                </span>
              </div>
            </div>
          )}
          {isOtherPopupVisible && (
            <div>
              <div
                className="popup"
                style={{
                  position: "absolute",
                  left: `${popupPosition.x}px`,
                  top: `${popupPosition.y}px`,
                }}
              >
                <div>
                  <img src={eye} alt="icon" />
                  <span
                    onClick={() =>
                      navigate(
                        `/monaco-solicitors-ai-letters/${selectedLetter.letterLink}`
                      )
                    }
                  >
                    Learn more
                  </span>
                </div>
                <div>
                  <img src={edit} alt="icon" className="generate-icon" />
                  <span
                    onClick={() => {
                      if (IsPremium || selectedLetter.link === "advice-note") {
                        navigate(`/data-inputs?letter=${selectedLetter.link}`);
                      } else {
                        showPremium(selectedLetter.link);
                      }
                    }}
                  >
                    Generate letter
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="dashboard-cta">
          <h4>Has Your Employer Offered You a Settlement Agreement?</h4>
          <p>Contact us right away to get legal advice</p>
          <a
            href="https://www.monacosolicitors.co.uk/contact-us?utm_source=grapple&utm_medium=dashboard&utm_campaign=contact-lawyer"
            target="_blank"
            className="button"
          >
            <img src={connect} alt="icon" />
            Contact lawyer
          </a>
        </div>
      </div>
      {openPopup && (
        <PremiumPopup
          name={userData.username}
          email={userData.email}
          authToken={authToken}
          letterLink={letterLink}
          onHide={onHide}
        />
      )}
      {openUpgradePopup && <UpgradePopup onHide={closePopup} />}
    </>
  );
}
