import Axios from "../api/axios";
import config from "../../config";

const forgotPassword = (email) => {
  return Axios.post(`${config.BASE_URL}auth/forgot-password`, {
    email: email,
  });
};

export default forgotPassword;
