import React, { useState } from "react";
import PropTypes from "prop-types";
import { Dialog } from "primereact/dialog";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import logo from "../assets/img/ms-logo.webp";
import sendMail from "../app/services/sendMail";
import { getDealId } from "./../shared/Helpers";
import { Button } from "primereact/button";

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Please enter your name"),
  email: Yup.string()
    .email("Invalid email")
    .required("Please enter your email address"),
});

const EmailModal = (props) => {
  const initialValues = {
    name: "",
    email: "",
  };
  const footerContent = (
    <Button label="Done" onClick={props.handleEmailModal} autoFocus />
  );
  const [showEmailModal, setShowEmailModal] = useState(false);
  const localDealId = getDealId();

  const handleSubmit = async (values) => {
    let letterText = "";
    let templateId = "";
    let fileName = "";
    const paidConUrl = localDealId
      ? `https://www.monacosolicitors.co.uk/book-your-consultation?id=${localDealId}`
      : "https://www.monacosolicitors.co.uk/book-your-paid-consultation";
    if (props.letterName === "grievance-letter") {
      letterText = props.letterData;
      templateId = "d-222a8db1fd4e4c7b97577ab8f4a4633d";
      fileName = "monaco_ai_gr_letter";
    } else if (props.letterName === "wp-letter") {
      letterText = props.letterData;
      templateId = "d-5e8d1925c2bb4c969469369aa79ee7d0";
      fileName = "monaco_ai_wp_letter";
    } else if (props.letterName === "et1-letter") {
      letterText = props.letterData;
      templateId = "d-5820f0f1298f4ac3ade17f39897ef7db";
      fileName = "monaco_ai_et1";
    } else if (props.letterName === "dsar-letter") {
      letterText = props.letterData;
      templateId = "d-99f15745ec174eea8bc33647bf251f40";
      fileName = "monaco_ai_dsar";
    } else if (props.letterName === "response-letter") {
      letterText = props.letterData;
      templateId = "d-e666093303644c8fa06882db37396f94";
      fileName = "monaco_ai_response";
    } else if (props.letterName === "witness-statement-letter") {
      letterText = props.letterData;
      templateId = "d-2b89388849d5456483e44d36b35b02b6";
      fileName = "monaco_ai_witness_statement";
    } else {
      letterText = props.letterData;
      templateId = "d-a3433582fd424844814ed90c4c5b4d2c";
      fileName = "advice-note";
    }

    try {
      await sendMail(
        values.email,
        values.name,
        values.email,
        letterText,
        fileName,
        templateId,
        paidConUrl
      );
    } catch (error) {
      console.error(error);
    } finally {
      setShowEmailModal(true);
    }
  };

  return (
    <>
      {showEmailModal ? (
        <Dialog
          className="thank-you-modal"
          visible={props.visible}
          draggable={false}
          style={{ maxWidth: "360px" }}
          onHide={props.handleEmailModal}
          header={<img src={logo} alt={logo} width="80px" />}
          footer={footerContent}
        >
          <h4>Thank You</h4>
          <p>Your document has been emailed to you</p>
        </Dialog>
      ) : (
        <Dialog
          className="email-modal"
          visible={props.visible}
          draggable={false}
          style={{ maxWidth: "390px" }}
          onHide={props.handleEmailModal}
          header={<img src={logo} alt={logo} width="200px" />}
        >
          <h4>Send this to me</h4>
          <p>Enter your name and email</p>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isValid }) => (
              <Form>
                <div>
                  <Field
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Name"
                    className="textbox"
                  />
                  <ErrorMessage name="name" component="div" className="error" />
                </div>
                <div>
                  <Field
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Your email"
                    className="textbox"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                  />
                </div>
                <button type="submit" disabled={!isValid}>
                  Send now
                </button>
              </Form>
            )}
          </Formik>
        </Dialog>
      )}
    </>
  );
};

EmailModal.propTypes = {
  handleEmailModal: PropTypes.func,
  visible: PropTypes.bool,
  letterName: PropTypes.string,
  letterData: PropTypes.string,
};

export default EmailModal;
