import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import eye from "../assets/img/eye.svg";
import { setToken } from "../shared/Helpers";
import { useNavigate } from "react-router-dom";
import setPasswordService from "../app/services/setPasswordService";
import { Toast } from "primereact/toast";

const passwordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Please confirm your password"),
});

const SetPassword = () => {
  const initialValues = {
    password: "",
    confirmPassword: "",
  };
  const [showPassword, setShowPassword] = useState(false);
  const [resetToken, setResetToken] = useState("");
  const navigate = useNavigate();

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const toast = useRef(null);

  useEffect(() => {
    const queryString = window.location.search;
    const queryParams = new URLSearchParams(queryString);
    const code = queryParams.get("code");
    if (code) {
      setResetToken(code);
    } else {
      navigate("/");
    }
  }, [navigate]);

  const handleSubmit = async (values) => {
    values.token = resetToken;
    try {
      const response = await setPasswordService(values);
      if (response?.error) {
        throw response?.data?.error;
      } else {
        setToken(response?.data?.jwt);
        navigate("/dashboard");
      }
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Link expired please try again",
        life: 3000,
      });
    }
  };

  return (
    <div className="reset-password auth-section">
      <Formik
        initialValues={initialValues}
        validationSchema={passwordValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ isValid }) => (
          <Form className="forgotPassword">
            <h4>Set Your Password</h4>
            <p>Please enter a new password to gain access to your account.</p>
            <div className="password-field">
              <Field
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                placeholder="Enter your new password"
                className="textbox"
              />
              <img
                src={eye}
                alt={eye}
                width="20px"
                onClick={handleTogglePassword}
              />
              <ErrorMessage name="password" component="div" className="error" />
            </div>
            <div className="password-field">
              <Field
                type={showPassword ? "text" : "password"}
                id="confirmPassword"
                name="confirmPassword"
                placeholder="Re-enter your new password"
                className="textbox"
              />
              <img
                src={eye}
                alt={eye}
                width="20px"
                onClick={handleTogglePassword}
              />
              <ErrorMessage
                name="confirmPassword"
                component="div"
                className="error"
              />
            </div>
            {/* {errorMessage && <p className="error response">{errorMessage}</p>} */}
            <button type="submit" disabled={!isValid}>
              Set Password
            </button>
          </Form>
        )}
      </Formik>
      <Toast ref={toast} />
    </div>
  );
};

export default SetPassword;
