import Axios from "../api/axios";
import config from "../../config";

const generateCaseDetailLetter = (formData) => {
  return Axios.post(`${config.BASE_URL}update-case`, {
    formData: formData,
  });
};

export default generateCaseDetailLetter;
