import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import eye from "../assets/img/eye.svg";
import * as Yup from "yup";
import { setToken } from "../shared/Helpers";
import { useNavigate } from "react-router-dom";
import signup from "../app/services/signup";
import sendMail from "../app/services/sendMail";
import updateUser from "../app/services/updateUser";
import { getDealId } from "./../shared/Helpers";
import PropTypes from "prop-types";
import getUser from "../app/services/getUser";

function SignUpPopup(props) {
  const signupValidationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter your name"),
    email: Yup.string()
      .email("Invalid email")
      .required("Please enter your email address"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    termsAndConditions: Yup.bool().oneOf(
      [true],
      "You must accept the terms & conditions"
    ),
    personalUse: Yup.bool().oneOf(
      [true],
      "You must confirm this is for personal use"
    ),
  });
  const initialValues = {
    name: "",
    email: "",
    password: "",
    termsAndConditions: false,
    personalUse: false,
  };
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const localDealId = getDealId();
  const navigate = useNavigate();
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const moveToLogin = () => {
    navigate("/login");
  };
  const saveLetter = async (token) => {
    let updateField = {};
    const timeStamp = new Date().getTime();
    switch (props.letterName) {
      case "advice-note":
        updateField = {
          isAdviceNoteGenerated: true,
          adviceNote: props.finalLetter,
          adviceNoteSetOn: timeStamp,
        };
        break;
      case "wp-letter":
        updateField = {
          isWpLetterGenerated: true,
          wpLetter: props.finalLetter,
          wpLetterSetOn: timeStamp,
          wpLetterBody: props.mainContent,
        };
        break;
      case "grievance-letter":
        updateField = {
          isGrievanceLetterGenerated: true,
          grievanceLetter: props.finalLetter,
          grievanceLetterSetOn: timeStamp,
          grievanceLetterBody: props.mainContent,
        };
        break;
      case "et1-letter":
        updateField = {
          isEt1LetterGenerated: true,
          et1Letter: props.finalLetter,
          et1LetterSetOn: timeStamp,
          et1LetterBody: props.mainContent,
        };
        break;
      case "witness-statement-letter":
        updateField = {
          isWitnessStatementLetterGenerated: true,
          witnessStatementLetter: props.finalLetter,
          witnessStatementLetterSetOn: timeStamp,
          witnessStatementLetterBody: props.mainContent,
        };
        break;
      case "dsar-letter":
        updateField = {
          isDsarLetterGenerated: true,
          dsarLetter: props.finalLetter,
          dsarLetterSetOn: timeStamp,
          dsarLetterBody: props.mainContent,
        };
        break;
      case "response-letter":
        updateField = {
          isRespondToEmployerLetterGenerated: true,
          respondToEmployer: props.finalLetter,
          respondToEmployerSetOn: timeStamp,
        };
        break;
      default:
        console.log("Letter type does not match any update criteria.");
        return;
    }
    try {
      await updateUser(token, updateField);
      navigate("/dashboard");
    } catch (e) {
      console.error(
        "An error occurred while updating the user and letter: ",
        e
      );
    }
  };
  const sendToMail = async (token) => {
    let letterText = "";
    let templateId = "";
    let fileName = "";
    const paidConUrl = localDealId
      ? `https://www.monacosolicitors.co.uk/book-your-consultation?id=${localDealId}`
      : "https://www.monacosolicitors.co.uk/book-your-paid-consultation";
    if (props.letterName === "grievance-letter") {
      letterText = props.finalLetter;
      templateId = "d-222a8db1fd4e4c7b97577ab8f4a4633d";
      fileName = "monaco_ai_gr_letter";
    } else if (props.letterName === "wp-letter") {
      letterText = props.finalLetter;
      templateId = "d-5e8d1925c2bb4c969469369aa79ee7d0";
      fileName = "monaco_ai_wp_letter";
    } else if (props.letterName === "et1-letter") {
      letterText = props.finalLetter;
      templateId = "d-5820f0f1298f4ac3ade17f39897ef7db";
      fileName = "monaco_ai_et1";
    } else if (props.letterName === "dsar-letter") {
      letterText = props.finalLetter;
      templateId = "d-99f15745ec174eea8bc33647bf251f40";
      fileName = "monaco_ai_dsar";
    } else if (props.letterName === "response-letter") {
      letterText = props.finalLetter;
      templateId = "d-e666093303644c8fa06882db37396f94";
      fileName = "monaco_ai_response";
    } else if (props.letterName === "witness-statement-letter") {
      letterText = props.finalLetter;
      templateId = "d-2b89388849d5456483e44d36b35b02b6";
      fileName = "monaco_ai_witness_statement";
    } else {
      letterText = props.finalLetter;
      templateId = "d-a3433582fd424844814ed90c4c5b4d2c";
      fileName = "advice-note";
    }
    try {
      const response = await getUser(token);
      const userData = response.data;
      await sendMail(
        userData?.email,
        userData?.username,
        userData?.email,
        letterText,
        fileName,
        templateId,
        paidConUrl
      );
    } catch (error) {
      console.error(error);
    }
  };
  const handleSignupSubmit = async (values) => {
    const username = values.name;
    const email = values.email;
    const password = values.password;
    try {
      const response = await signup(username, email, password);
      if (response?.error) {
        throw response?.data?.error;
      } else {
        setToken(response?.data?.jwt);
        const templateId = "d-84f24edfe8034f679cb40ed8d7934601";
        const letterText = "";
        const fileName = "";
        try {
          await sendMail(
            email,
            username,
            email,
            letterText,
            fileName,
            templateId
          );
        } catch (e) {
          console.log(e);
        }
        if (props.authPopupType === "signup") {
          saveLetter(response?.data?.jwt);
        } else {
          sendToMail(response?.data?.jwt);
          saveLetter(response?.data?.jwt);
        }
      }
    } catch (e) {
      const error = e?.response?.data?.error?.message;
      setErrorMessage(error);
    }
  };
  return (
    <>
      <div className="auth-popup signup">
        <div className="auth-popup-container">
          <h2>Create a Free Account to View and Save your Document</h2>
          <p>
            Your document will be stored securely so you can access it anytime,
            from any device.
          </p>
          <Formik
            initialValues={initialValues}
            validationSchema={signupValidationSchema}
            onSubmit={handleSignupSubmit}
          >
            {({ isValid }) => (
              <Form>
                <div>
                  <Field
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Name"
                    className="textbox"
                  />
                  <ErrorMessage name="name" component="div" className="error" />
                </div>
                <div>
                  <Field
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    className="textbox"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                  />
                </div>
                <div>
                  <div className="password-field">
                    <Field
                      type={showPassword ? "text" : "password"}
                      id="password"
                      name="password"
                      placeholder="Password"
                      className="textbox"
                    />
                    <img
                      src={eye}
                      alt={eye}
                      width="20px"
                      onClick={handleTogglePassword}
                    />
                  </div>
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="checkbox-group">
                  <label>
                    <Field type="checkbox" name="termsAndConditions" />
                    <span>
                      I accept the <Link to="/terms">terms & conditions</Link>
                    </span>
                  </label>
                  <ErrorMessage
                    name="termsAndConditions"
                    component="div"
                    className="error"
                  />
                </div>

                <div className="checkbox-group">
                  <label>
                    <Field type="checkbox" name="personalUse" />
                    <span>I am only using this for personal use</span>
                  </label>
                  <ErrorMessage
                    name="personalUse"
                    component="div"
                    className="error"
                  />
                </div>
                {errorMessage && (
                  <p className="error response">{errorMessage}</p>
                )}
                <div className="signup-buttoms">
                  <button type="submit">Create account</button>
                </div>
                <div className="action-type" onClick={moveToLogin}>
                  <p>
                    Already have an account?{" "}
                    <span>
                      <u>Log in</u>
                    </span>
                  </p>
                </div>
              </Form>
            )}
          </Formik>
          <div className="close-button" onClick={props.onHide}>
            Back
          </div>
          <div className="footer-section">
            <p>
              Your information is kept completely private and not given to
              anyone. By continuing you agree to the Terms and Conditions of
              Monaco Solicitors AI premium service
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

SignUpPopup.propTypes = {
  letterName: PropTypes.string,
  finalLetter: PropTypes.string,
  authPopupType: PropTypes.string,
  mainContent: PropTypes.string,
  onHide: PropTypes.func,
};

export default SignUpPopup;
