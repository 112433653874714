import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import YoutubeEmbed from "../components/YoutubeEmbed";
import ReactMarkdown from "react-markdown";
import EmailIcon from "../assets/img/Email.svg";
import CheckLetterIcon from "../assets/img/check-letter.svg";
import sendMail from "../app/services/sendMail";
import getUser from "../app/services/getUser";
import ThankYouModal from "../components/ThankYouModal";
import PremiumPopup from "../components/PremiumPopup";
import { getToken, getDealId, getLoginType } from "./../shared/Helpers";
import getWpLetter from "../app/services/getWpLetter";
import getGrievanceLetter from "../app/services/getGrievanceLetter";
import getEt1Letter from "../app/services/getEt1Letter";
import getDsarLetter from "../app/services/getDsarLetter";
import getWitnessStatementLetter from "../app/services/getWitnessLetter";
import GrappleLettersList from "../components/GrappleLettersList";
import EmailModal from "../components/EmailModal";

function GeneratedLetter() {
  const { state } = useLocation();
  const { letterName, letterTitle, letterData, mainContent, userData } =
    state || {};
  const localDealId = getDealId();
  const authToken = getToken();
  const navigate = useNavigate();
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [letterLink, setLetterLink] = useState("");
  const [showModal, setShowModal] = useState(false);
  const handleEmailModal = () => {
    setShowEmailModal(!showEmailModal);
  };
  const loginType = getLoginType();

  const youtubeEmbedIds = {
    "advice-note": "Eyzj9Ofh0GI",
    "grievance-letter": "E46KLxeh9h4",
    "wp-letter": "4hF_OsyD0Ds",
    "et1-letter": "q1G3tVsCuf8",
    "dsar-letter": "KmKXKMJvMhc",
    "response-letter": "Eyzj9Ofh0GI",
    "witness-statement-letter": "4hF_OsyD0Ds",
  };

  const embedId = youtubeEmbedIds[letterName] || "Eyzj9Ofh0GI";

  const sendToMail = async () => {
    let letterText = "";
    let templateId = "";
    let fileName = "";
    const paidConUrl = localDealId
      ? `https://www.monacosolicitors.co.uk/book-your-consultation?id=${localDealId}`
      : "https://www.monacosolicitors.co.uk/book-your-paid-consultation";
    if (letterName === "grievance-letter") {
      letterText = letterData;
      templateId = "d-222a8db1fd4e4c7b97577ab8f4a4633d";
      fileName = "monaco_ai_gr_letter";
    } else if (letterName === "wp-letter") {
      letterText = letterData;
      templateId = "d-5e8d1925c2bb4c969469369aa79ee7d0";
      fileName = "monaco_ai_wp_letter";
    } else if (letterName === "et1-letter") {
      letterText = letterData;
      templateId = "d-5820f0f1298f4ac3ade17f39897ef7db";
      fileName = "monaco_ai_et1";
    } else if (letterName === "dsar-letter") {
      letterText = letterData;
      templateId = "d-99f15745ec174eea8bc33647bf251f40";
      fileName = "monaco_ai_dsar";
    } else if (letterName === "response-letter") {
      letterText = letterData;
      templateId = "d-e666093303644c8fa06882db37396f94";
      fileName = "monaco_ai_response";
    } else if (letterName === "witness-statement-letter") {
      letterText = letterData;
      templateId = "d-2b89388849d5456483e44d36b35b02b6";
      fileName = "monaco_ai_witness_statement";
    } else {
      letterText = letterData;
      templateId = "d-a3433582fd424844814ed90c4c5b4d2c";
      fileName = "advice-note";
    }
    if (loginType === "lawyer") {
      setShowModal(!showModal);
    } else {
      try {
        const response = await getUser(authToken);
        const userData = response.data;
        await sendMail(
          userData?.email,
          userData?.username,
          userData?.email,
          letterText,
          fileName,
          templateId,
          paidConUrl
        );
      } catch (error) {
        console.error(error);
      } finally {
        setShowEmailModal(true);
      }
    }
  };

  const checkLetter = async (letterName) => {
    const fetchLetter = async (letterType) => {
      switch (letterType) {
        case "wp-letter":
          return { letter: await getWpLetter() };
        case "grievance-letter":
          return { letter: await getGrievanceLetter() };
        case "et1-letter":
          return { letter: await getEt1Letter() };
        case "dsar-letter":
          return { letter: await getDsarLetter() };
        case "witness-statement-letter":
          return { letter: await getWitnessStatementLetter() };
        default:
          throw new Error("Invalid letter type");
      }
    };

    try {
      const { letter } = await fetchLetter(letterName);
      let letterInfo = {};
      if (letter) {
        letterInfo = letter.data.data.attributes;
      }

      if (userData.premium) {
        navigate("/check-letter", {
          state: {
            letterName: letterName,
            letterTitle: letterTitle,
            letterInfo: letterInfo,
            mainContent: mainContent,
            userData: userData,
          },
        });
      } else {
        setOpenPopup(true);
        setLetterLink("check-letter");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const back = async () => {
    navigate("/dashboard");
  };

  const onHide = () => {
    setOpenPopup(false);
  };

  if (!state) {
    return (
      <div className="letter">
        <div className="letter-header">
          <p>No letter data available.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="letter">
      <div className="letter-container">
        <div className="letter-body">
          <div className="letter-content-container">
            <h5>{letterTitle}</h5>
            <div className="letter-content">
              <ReactMarkdown>{letterData}</ReactMarkdown>
            </div>
            <div className="letter-actions">
              {letterName !== "response-letter" &&
                letterName !== "advice-note" && (
                  <div
                    className="check-letter"
                    onClick={() => checkLetter(letterName)}
                  >
                    <img src={CheckLetterIcon} alt="check-letter-icon" /> Check
                    Letter
                  </div>
                )}
              <div className="send-email" onClick={sendToMail}>
                <img src={EmailIcon} alt="email-icon" /> Send to email
              </div>
            </div>
          </div>
          <div className="letter-sidebar">
            <h5>Learn more</h5>
            <YoutubeEmbed embedId={embedId} width="100%" height="185" />
            <div className="save-button" onClick={back}>
              Back
            </div>
            <div className="other-letters">
              <h5>Other letters</h5>
              <GrappleLettersList
                link={`letterpage?letterName=${letterName}`}
              />
            </div>
          </div>
        </div>
      </div>
      {showEmailModal && (
        <ThankYouModal
          handleModal={handleEmailModal}
          visible={showEmailModal}
        />
      )}
      {openPopup && (
        <PremiumPopup
          name={userData.username}
          email={userData.email}
          authToken={authToken}
          letterLink={letterLink}
          onHide={onHide}
        />
      )}
      {showModal && (
        <EmailModal
          handleEmailModal={sendToMail}
          visible={showModal}
          letterName={letterName}
          letterData={letterData}
        />
      )}
    </div>
  );
}

export default GeneratedLetter;
