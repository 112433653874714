import React from "react";
import PropTypes from "prop-types";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import logo from "../assets/img/document.svg";

const ThankYouModal = (props) => {
  const footerContent = (
    <Button label="Done" onClick={props.handleModal} autoFocus />
  );

  return (
    <Dialog
      className="thank-you-modal"
      visible={props.visible}
      draggable={false}
      style={{ maxWidth: "360px" }}
      onHide={props.handleModal}
      header={<img src={logo} alt={logo} width="80px" />}
      footer={footerContent}
    >
      <h4>Thank You</h4>
      <p>Your document has been emailed to you</p>
    </Dialog>
  );
};

ThankYouModal.propTypes = {
  handleModal: PropTypes.func,
  visible: PropTypes.bool,
};

export default ThankYouModal;
