import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LetterContent from "../components/LetterContent";
import { Link } from "react-router-dom";
import BackIcon from "../assets/img/back.svg";

function Letter() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const {
    lastPage,
    isUserLoggedin,
    letterName,
    letterTitle,
    paragraphs,
    letterInfo,
    adviceNote,
    userInput,
    dataInputs,
    email,
  } = state || {};

  useEffect(() => {
    if (state) return;
    const targetRoute = isUserLoggedin
      ? "/dashboard"
      : "/monaco-solicitors-ai-letters";
    navigate(targetRoute);
  }, [isUserLoggedin, navigate, state]);

  if (!state) {
    return (
      <div className="letter">
        <div className="letter-header">
          <p>No letter data available.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="letter">
      <div className="letter-header">
        <Link to={lastPage} className="back-button">
          <img src={BackIcon} alt="back-icon" />
          Back
        </Link>
      </div>
      <div className="letter-container">
        <div className="letter-body">
          <LetterContent
            isUserLoggedin={isUserLoggedin}
            letterName={letterName}
            letterTitle={letterTitle}
            letterInfo={letterInfo}
            paragraphs={paragraphs}
            adviceNote={adviceNote}
            userInput={userInput}
            dataInputs={dataInputs}
            email={email}
          />
        </div>
      </div>
    </div>
  );
}

export default Letter;
