const host = "https://api.monacosolicitors.grapple.uk";

const config = {
  domain: host,
  BASE_URL: `${host}/api/`,
  ROTA_URL: "https://cms.monacosolicitors.co.uk/wp-json/rota/v1/",
  token:
    "3788b1716397f2270da97be5dc22bcd2e4290cf8a9e20421ecda4e7eb648ba7e6af44dd4cb77e0d299b3aa79874673dc57d1a3e2e8f9ef000b53096a6951d730dbc31f62f044ef098ac24be760801c5bbe22a0068b478117b1c8404f2f986b464cc34066e50b03c06bc84909de2cffbc003a0c18760b6865fefcefc8b52bc280",
  baseName: "/",
  language: "en",
  stripe_key:
    "pk_live_51O9uqwJuV8CPgQ4yfM6SIO0d8E3dnypqKm2mjOdk3wl6Ku6NtSOhSzKYm66iVIk7GdfldMCSXRgqrxIEzavcR3L100fCGrd9ou",
};

export default config;
