import React, { useEffect } from "react";
import setTrackingParams from "../utils/setTrackingParams";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  useEffect(() => {
    (async () => {
      setTrackingParams(location.pathname + location.search);
    })();
  }, [location]);

  return (
    <div className="footer">
      <div className="footer-container">
        <div className="footer-top">
          <p>Powered by Grapple</p>
        </div>
        <div className="footer-bottom">
          <p>
            Monaco Solicitors make every attempt to ensure the factual accuracy
            of website content at the time of publication. Any guidance or tips
            given is for information only. Nothing should be construed as being
            legal advice or relied upon as such or perceived as creating a
            lawyer-client relationship.
          </p>
          <p>© 2022 Monaco Solicitors Ltd, Registered company no. 08487857</p>
          <p>Solicitors Regulation Authority ID no: 621671</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
