import FounderImage from "../assets/img/founder.svg";
import YoutubeEmbed from "./YoutubeEmbed";
import PropTypes from "prop-types";

const Founder = ({ data }) => {
  return (
    <div className="founder">
      <div className="founder-container">
        <div>
          {data?.FounderTitle && <h4>{data?.FounderTitle}</h4>}
          {data?.FounderTitle && <p>{data?.FounderDescription}</p>}
          <div className="author">
            <img src={FounderImage} alt="founder" />
            {data?.FounderTitle && <p> {data?.FounderName}</p>}
          </div>
        </div>
        <div>
          {data?.YoutubeVideo && (
            <YoutubeEmbed
              embedId={data?.YoutubeVideo}
              width="100%"
              height="310"
            />
          )}
        </div>
      </div>
    </div>
  );
};

Founder.propTypes = {
  data: PropTypes.object,
};

export default Founder;
