import Axios from "../api/axios";
import config from "../../config";

const sendMail = (
  to,
  name,
  number,
  letterText,
  fileName,
  templateId,
  paidConUrl
) => {
  return Axios.post(`${config.BASE_URL}send-email`, {
    to: to,
    name: name,
    number: number,
    letterText: letterText,
    fileName: fileName,
    templateId: templateId,
    paidConUrl: paidConUrl,
  });
};

export default sendMail;
