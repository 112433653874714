import React from "react";
import PrivateHeader from "../components/PrivateHeader"; // The public header
import Footer from "../components/Footer";
import UserLayout from "./userLayout";

const PublicLayout = ({ children }) => {
  return (
    <>
      <PrivateHeader />
      <UserLayout>{children}</UserLayout>
      <Footer />
    </>
  );
};

export default PublicLayout;
