import React, { useEffect, useState } from "react";
import getTerms from "../app/services/getTerms";
import ReactMarkdown from "react-markdown";
import Breadcrumb from "../components/Breadcrumb";

function Help() {
  const [data, setData] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      const {
        data: {
          data: { attributes },
        },
      } = await getTerms();
      setData(attributes.Content);
    };
    fetchData();
  }, []);
  return (
    <div className="helpsection">
      <Breadcrumb data="Term and condition" type="page" />
      <ReactMarkdown>{data}</ReactMarkdown>
    </div>
  );
}

export default Help;
