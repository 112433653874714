import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const UpdateAtjData = (props) => {
  const navigate = useNavigate();
  const updateAnswer = () => {
    navigate(`/case-details?formType=${props.formType}`);
  };
  return (
    <div className="action-popup update-letter">
      <h4>Edit</h4>
      <p>Do you want to edit your answers?</p>
      <div className="action-popup-footer">
        <button className="button cancel-button" onClick={props.onHide}>
          No, take me back
        </button>
        <button className="button logout-button" onClick={updateAnswer}>
          Yes, edit
        </button>
      </div>
    </div>
  );
};

UpdateAtjData.propTypes = {
  formType: PropTypes.string,
  onHide: PropTypes.func,
};

export default UpdateAtjData;
