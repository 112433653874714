import React, { useEffect, useState } from "react";
import getHelp from "../app/services/getHelp";
import ReactMarkdown from "react-markdown";
import Breadcrumb from "../components/Breadcrumb";
import expandMore from "../assets/img/more.svg";
import expandLess from "../assets/img/less.svg";

function Help() {
  const [data, setData] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const {
        data: {
          data: { attributes },
        },
      } = await getHelp();
      setData(attributes);
    };
    fetchData();
  }, []);

  const splitContentIntoSections = (content) => {
    // Adjust splitting logic if necessary based on your Markdown structure
    return content
      .split("\n## ")
      .map((section, index) => (index > 0 ? "## " : "") + section);
  };

  const Section = ({ content }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const toggleExpanded = () => setIsExpanded(!isExpanded);

    const contentNeedsToggle = content.length > 230;
    const previewContent =
      content.substring(0, 230) + (content.length > 230 ? "..." : "");

    return (
      <div
        style={{
          borderTop: "1px solid #D9D9D9",
          paddingTop: "32px",
          marginTop: "8px",
        }}
      >
        <ReactMarkdown
          children={
            isExpanded || !contentNeedsToggle ? content : previewContent
          }
        />
        {contentNeedsToggle && (
          <button
            onClick={toggleExpanded}
            style={{
              marginTop: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {isExpanded ? <img src={expandMore} alt="more-icon" /> : <img src={expandLess} alt="less-icon" />}
            <span style={{ marginLeft: "5px" }}>
              {isExpanded ? "Read Less" : "Read More"}
            </span>
          </button>
        )}
      </div>
    );
  };

  return (
    <div className="help">
      <div className="banner">
        <Breadcrumb data="Help" type="page" />
        <div className="banner-container">
          <div className="banner-caption">
            {data.HeaderText && (
              <ReactMarkdown>{data.HeaderText}</ReactMarkdown>
            )}
          </div>
        </div>
      </div>
      <div className="help-content">
        {data.Content
          ? splitContentIntoSections(data.Content).map((section, index) => (
              <Section key={index} content={section} />
            ))
          : null}
      </div>
    </div>
  );
}

export default Help;
