import React from "react";
import AppRoute from "./AppRoute";
import "./scss/app.scss";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-K7KMLHGB",
};

TagManager.initialize(tagManagerArgs);

function App() {
  return (
    <div className="App">
      <AppRoute />
    </div>
  );
}

export default App;
