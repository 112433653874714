import Axios from "../api/axios";
import config from "../../config";

const getAdviceNotes = (topics) => {
  return Axios.post(`${config.BASE_URL}advice-note`, {
    inputData: topics,
  });
};

export default getAdviceNotes;
