import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import PropTypes from "prop-types";
import updateUserPayment from "../app/services/updateUserPayment";
import createPayment from "../app/services/createPaymentIntend";
import cancelSubscription from "../app/services/cancelSubscription";

const UpdatePremium = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleGAEventOnPayment = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "form",
      form: {
        action: "submit",
        id: "grapple-payment",
        name: "grapple",
        type: "payment complete",
        category: "purchase",
        success: true,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    try {
      await cancelSubscription(props.email);
      const cardElement = elements.getElement(CardElement);
      const paymentMethodResult = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: { email: props.email },
      });

      if (paymentMethodResult.error) {
        setMessage(paymentMethodResult.error.message);
        setIsLoading(false);
        return;
      }

      const paymentMethodId = paymentMethodResult.paymentMethod.id;
      const response = await createPayment(props.email, paymentMethodId);
      if (response?.status === 200) {
        const subscriptionId = response?.data?.subscriptionId;
        const element = document.querySelector("#root > .App");
        element.className = "App";
        element.classList.add("premium-user");
        const timeStamp = new Date().getTime();

        await updateUserPayment(
          props.authToken,
          true,
          timeStamp,
          "£49.99",
          subscriptionId
        );
        handleGAEventOnPayment();
        window.location.reload();
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setMessage(error?.response?.data?.error?.message);
    }

    setIsLoading(false);
  };

  return (
    <>
      <div className="update-premium payment-form">
        <form id="payment-form" onSubmit={handleSubmit}>
          <CardElement id="payment-element" />
          <button disabled={isLoading || !stripe || !elements} id="submit">
            <span id="button-text">
              {isLoading ? (
                <div className="spinner" id="spinner"></div>
              ) : (
                "Update"
              )}
            </span>
          </button>
          {message && <div id="payment-message">{message}</div>}
        </form>
      </div>
    </>
  );
};

UpdatePremium.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  authToken: PropTypes.string,
};

export default UpdatePremium;
