import Axios from "../api/axios";
import config from "../../config";

const createPayment = (email, paymentMethodId) => {
  return Axios.post(`${config.BASE_URL}payments/create-payment-intent`, {
    email,
    paymentMethodId
  });
};

export default createPayment;
