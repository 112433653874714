/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import getLetterDetails from "../app/services/getLetterDetails";
import Breadcrumb from "../components/Breadcrumb";
import YoutubeEmbed from "../components/YoutubeEmbed";
import PremiumPopup from "../components/PremiumPopup";
import ReactMarkdown from "react-markdown";
import MSLogo from "../assets/img/ms-logo.svg";
import { getToken } from "./../shared/Helpers";
import getUser from "../app/services/getUser";

const slugToLetterNameMap = {
  "advice-note": "advice-note",
  "without-prejudice-letter": "wp-letter",
  "grievance-letters": "grievance-letter",
  "et1-details-of-claim": "et1-letter",
  "respond-to-employer": "response-letter",
  "data-subject-request": "dsar-letter",
  "witness-statement-letter": "witness-statement-letter",
};

function LetterDetail() {
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [userData, setUserData] = useState({});
  const [openPopup, setOpenPopup] = useState(false);
  const [letterLink, setLetterLink] = useState("");
  let letterName = slugToLetterNameMap[slug] || "advice-note";
  const authToken = getToken();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getLetterDetails(slug);
        setData(response.data.data[0].attributes);
      } catch (error) {
        console.error("Failed to fetch letter details:", error);
        setError("Failed to load data. Please try again later.");
      }
    };
    fetchData();
  }, [slug]);

  useEffect(() => {
    const fetchLoggedInUser = async (token) => {
      try {
        const response = await getUser(token);
        const data = response.data;
        setUserData(data);
      } catch (error) {
        console.error(error);
      }
    };
    if (authToken) {
      fetchLoggedInUser(authToken);
    }
  }, [authToken]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!data) {
    return <div>Loading...</div>;
  }

  const showPremium = (t) => {
    setOpenPopup(true);
    setLetterLink(letterName);
  };

  const onHide = () => {
    setOpenPopup(false);
  };

  const {
    Title,
    BannerDescription,
    ButtonText,
    YoutubeVideo,
    Description,
    TableContent,
    IsPremium,
  } = data;

  const createMarkup = (htmlContent) => {
    return { __html: htmlContent };
  };

  return (
    <>
      <div className={`letter-details ${IsPremium ? "premium" : "free"}`}>
        <div className="banner">
          <Breadcrumb data="MSAI Letters" data2={Title} type="page" />
          <div className="banner-container">
            <div className="banner-caption">
              {Title && <h1>{Title}</h1>}
              {BannerDescription && (
                <ReactMarkdown>{BannerDescription}</ReactMarkdown>
              )}
              {!IsPremium ? (
                <Link
                  className="button get-started"
                  to={`/data-inputs?letter=${letterName}`}
                >
                  {ButtonText}
                </Link>
              ) : userData && authToken ? (
                userData.premium ? (
                  <Link
                    className="button get-started"
                    to={`/data-inputs?letter=${letterName}`}
                  >
                    Generate Document
                  </Link>
                ) : !userData.premium ? (
                  <div
                    className="button get-started"
                    onClick={() => showPremium(letterName)}
                  >
                    Unlock Monaco Solicitors AI Premium
                  </div>
                ) : (
                  <Link className="button get-started" to="/dashboard">
                    Go to Dashboard
                  </Link>
                )
              ) : (
                <Link className="button get-started" to="/premium-signup">
                  Unlock Monaco Solicitors AI Premium
                </Link>
              )}
            </div>
            {/* {BannerImage?.data?.attributes?.url && (
              <div className="banner-image">
                <img
                  src={`${config.domain}${BannerImage.data.attributes.url}`}
                  alt="banner"
                />
                {IsPremium && (
                  <div className="premium-letter">Premium Letter</div>
                )}
              </div>
            )} */}
            {YoutubeVideo && (
              <YoutubeEmbed embedId={YoutubeVideo} width="100%" height="310" />
            )}
          </div>
        </div>
        <div className="content">
          <div>
            <div className="desc">
              <ReactMarkdown>{Description}</ReactMarkdown>
            </div>
            <div className="table-content">
              <div dangerouslySetInnerHTML={createMarkup(TableContent)} />
            </div>
          </div>
          <div className="single-sidebar">
            <div className="ms-book-consultation">
              <div className="ms-book-consultation-container">
                <div>
                  <img src={MSLogo} alt="ms-logo" />
                </div>
                <div>
                  <h4>Book a Consultation Package</h4>
                  <p>
                    Our partners, Monaco Solicitors, are the biggest law firm in
                    the UK representing employees only with over a thousand
                    reviews averaging 4.8 stars!
                  </p>
                  <a
                    href="https://www.monacosolicitors.co.uk/book-your-paid-consultation?utm_source=grapple&utm_medium=right-hand-side&utm_campaign=book-consultation"
                    target="_blank"
                  >
                    Book Consultation
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openPopup && (
        <PremiumPopup
          name={userData.username}
          email={userData.email}
          authToken={authToken}
          letterLink={letterLink}
          onHide={onHide}
        />
      )}
    </>
  );
}

export default LetterDetail;
