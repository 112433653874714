import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getToken } from "./../shared/Helpers";
import premiumSignup from "../assets/img/premium-singup.svg";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../components/CheckoutForm";
import config from "../config";

const Signup = () => {
  const navigate = useNavigate();
  const authToken = getToken();
  const stripePromise = loadStripe(config.stripe_key);

  useEffect(() => {
    if (authToken) {
      navigate("/dashboard");
    }
  }, [authToken, navigate]);

  return (
    <div className="premium signup auth">
      <div className="auth-form">
        <div className="form-inner">
          <h5>Get Full Access with Monaco Solicitors AI Premium</h5>
          <p>
            Monaco Solicitors AI Premium empowers you to create letters crucial
            for your case and respond to employers with confidence. Craft,
            refine, and send with ease.
          </p>
          <div className="premium-plan">
            <h3>£49.99/month </h3>
            <p>Cancel anytime</p>
          </div>
          <Elements stripe={stripePromise}>
            <CheckoutForm />
          </Elements>
        </div>
      </div>
      <div
        className="auth-image"
        style={{ backgroundImage: `url(${premiumSignup})` }}
      ></div>
    </div>
  );
};

export default Signup;
