import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { getToken } from "./../shared/Helpers";
import getUser from "../app/services/getUser";
import WpLetter from "../assets/img/wp-letter.svg";
import GrievanceLetter from "../assets/img/grevience-letter.svg";
import Et1 from "../assets/img/etl-letter.svg";
import Dsar from "../assets/img/dsar-letter.svg";
import RespondToEmployer from "../assets/img/response-icon.svg";
import WitnessStatement from "../assets/img/WittnesstatementBlue.svg";
import ExpandIcon from "../assets/img/expand-more.svg";

function RecommendedLetters({ letterName, isUserLoggedin }) {
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const fetchUserData = async () => {
      if (authToken && isUserLoggedin) {
        try {
          const response = await getUser(getToken());
          setUserData(response?.data);
        } catch (error) {
          console.error("Failed to fetch user data", error);
        }
      }
    };

    const authToken = getToken();
    fetchUserData();
  }, [isUserLoggedin]);

  const fullSequence = [
    {
      key: "advice-note",
      flag: "isWpLetterGenerated",
      component: WpLetter,
      title: "Without Prejudice Letter",
      description: "Asking for compensation, if you want to leave your job",
      link: "/monaco-solicitors-ai-letters/without-prejudice-letter",
    },
    {
      key: "wp-letter",
      flag: "isGrievanceLetterGenerated",
      component: GrievanceLetter,
      title: "Grievance Letter",
      description: "Complaining, especially if you want to keep your job",
      link: "/monaco-solicitors-ai-letters/grievance-letters",
    },
    {
      key: "grievance-letter",
      flag: "isEt1LetterGenerated",
      component: Et1,
      title: "ET1 Claim Form",
      description: "‘ET1’ Details of Claim document ",
      link: "/monaco-solicitors-ai-letters/et1-details-of-claim",
    },
    {
      key: "et1-letter",
      flag: "isWitnessStatementLetterGenerated",
      component: WitnessStatement,
      title: "Witness Statement",
      description: "Access information about personal data",
      link: "/monaco-solicitors-ai-letters/witness-statement-letter",
    },
    {
      key: "witness-statement-letter",
      flag: "isDsarLetterGenerated",
      component: Dsar,
      title: "DSAR",
      description: "Ask your employer for a copy of communications about you ",
      link: "/monaco-solicitors-ai-letters/data-subject-request",
    },
    {
      key: "dsar-letter",
      flag: "isRespondToEmployerLetterGenerated",
      component: RespondToEmployer,
      title: "Respond to Employer",
      description: "If your employer has written to you ",
      link: "/-solicitors-ai-letters/respond-to-employer",
    },
  ];

  let sequenceBasedOnLetterName = fullSequence.slice(
    fullSequence.findIndex((letter) => letter.key === letterName)
  );

  let lettersToShow = [];
  if (isUserLoggedin) {
    const nextLetterIndex = sequenceBasedOnLetterName.findIndex(
      (letter) => !userData[letter.flag]
    );
    lettersToShow =
      nextLetterIndex >= 0 ? [sequenceBasedOnLetterName[nextLetterIndex]] : [];
  } else {
    const startIndex = letterName
      ? fullSequence.findIndex((letter) => letter.key === letterName)
      : 0;
    lettersToShow = startIndex >= 0 ? [fullSequence[startIndex]] : [];
  }

  return (
    <div className="recommended-letters">
      {lettersToShow.length > 0 ? (
        lettersToShow.map((letter, index) => (
          <Link to={letter.link} key={index}>
            <div className="recommended-letter-item">
              <img src={letter.component} alt={`${letter.title} icon`} />
              <div>
                <p>{letter.title}</p>
                <p>{letter.description}</p>
              </div>
              <img src={ExpandIcon} alt="expand icon" />
            </div>
          </Link>
        ))
      ) : (
        <p></p>
      )}
    </div>
  );
}

RecommendedLetters.propTypes = {
  letterName: PropTypes.string,
  isUserLoggedin: PropTypes.bool,
};

export default RecommendedLetters;
