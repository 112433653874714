import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Arrow from "../assets/img/next-arrow.svg";
import AdviceNoteIcon from "../assets/img/advice-note-icon.svg";
import WPIcon from "../assets/img/WPL-icon.svg";
import GRIcon from "../assets/img/GL-icon.svg";
import ET1Icon from "../assets/img/ET1-icon.svg";
import REIcon from "../assets/img/RE-icon.svg";
import DSARIcon from "../assets/img/DSAR-icon.svg";
import getLetters from "../app/services/getLetters";
import checkIcon from "../assets/img/CheckLetter28x28.svg";
import WSIcon from "../assets/img/WitnessStatement.svg";

const iconMapping = {
  "advice-note": AdviceNoteIcon,
  "without-prejudice-letter": WPIcon,
  "grievance-letters": GRIcon,
  "et1-details-of-claim": ET1Icon,
  "respond-to-employer": REIcon,
  "data-subject-request": DSARIcon,
  "check-your-letter": checkIcon,
  "witness-statement-letter": WSIcon,
};

// const slugToLetterNameMap = {
//   "advice-note": "advice-note",
//   "without-prejudice-letter": "wp-letter",
//   "grievance-letters": "grievance-letter",
//   "et1-details-of-claim": "et1-letter",
//   "witness-statement-letter": "witness-statement-letter",
//   "respond-to-employer": "response-letter",
//   "data-subject-request": "dsar-letter",
// };

const letterNameToSlugMap = {
  "advice-note": "advice-note",
  "wp-letter": "without-prejudice-letter",
  "grievance-letter": "grievance-letters",
  "et1-letter": "et1-details-of-claim",
  "witness-statement-letter": "witness-statement-letter",
  "response-letter": "respond-to-employer",
  "dsar-letter": "data-subject-request",
};

const GrappleLettersList = ({ link }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getLetters();
      setData(response.data.data);
    };
    fetchData();
  }, []);

  const getIcon = (slug) => {
    return iconMapping[slug] || AdviceNoteIcon;
  };

  // const getLetterLink = (slug) => {
  //   return slugToLetterNameMap[slug] || "advice-note";
  // };
  const queryParams = new URLSearchParams(link.split("?")[1]);
  const letterName = queryParams.get("letterName");

  const items =
    link !== "letterDetail"
      ? data.filter((item) => item.attributes.Slug !== "check-your-letter")
      : data;

  const limitedItem = data.filter((item) => {
    const isLetterDetailPage = link.startsWith("letterpage");
    const shouldExcludeLetter =
      isLetterDetailPage &&
      letterNameToSlugMap[letterName] === item.attributes.Slug;
    return (
      item.attributes.Slug !== "respond-to-employer" &&
      item.attributes.Slug !== "check-your-letter" &&
      !shouldExcludeLetter
    );
  });

  let itemsToDisplay = [];
  itemsToDisplay = letterName === null ? items : limitedItem;

  return (
    <div className="grapple-letters-list">
      <div className="grapple-letters-list-container">
        {link === "letterDetail" && <h2>Most created letters</h2>}
        <div>
          {itemsToDisplay.length > 0 &&
            itemsToDisplay.map((item) => (
              <div className="letter-item" key={item.attributes.Slug}>
                <div className="letter-header">
                  <div className="letter-title">
                    <img
                      src={getIcon(item.attributes.Slug)}
                      alt={`${item.attributes.Title}-icon`}
                    />
                    <h5>
                      <Link
                        to={`/monaco-solicitors-ai-letters/${item.attributes.Slug}`}
                      >
                        {item.attributes.Title}
                      </Link>
                    </h5>
                  </div>
                  <Link
                    className="next"
                    to={`/monaco-solicitors-ai-letters/${item.attributes.Slug}`}
                  >
                    <img src={Arrow} alt="arrow-icon" />
                  </Link>
                </div>
                <p>{item.attributes.ShortDescription}</p>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

GrappleLettersList.propTypes = {
  link: PropTypes.string.isRequired,
};

export default GrappleLettersList;
