import Axios from "../api/axios";
import config from "../../config";

const updateUserPayment = (
  token,
  premium,
  permiumSetOn,
  pricePaid,
  subscriptionId
) => {
  return Axios.put(
    `${config.BASE_URL}user/me`,
    {
      premium: premium,
      permiumSetOn: permiumSetOn,
      pricePaid: pricePaid,
      subscriptionId: subscriptionId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export default updateUserPayment;
