import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import LoginImage from "../assets/img/login-banner.svg";
import * as Yup from "yup";
import forgotPassword from "../app/services/forgotPassword";
import { useNavigate } from "react-router-dom";
import { getToken } from "./../shared/Helpers";

const passwordValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Please enter your email address"),
});

const ForgotPassword = () => {
  const initialValues = {
    email: "",
  };
  const [errorMessage, setErrorMessage] = useState("");
  const [status, setStatus] = useState(false);
  const navigate = useNavigate();
  const authToken = getToken();
  const message = {
    heading: "Forgot your password",
    text: "Please enter the email you use to sign in",
  };

  const handlePasswordSubmit = async (value) => {
    try {
      await forgotPassword(value.email);
      setStatus(true);
    } catch (e) {
      const error = e?.response?.data?.error?.message;
      setErrorMessage(error);
    }
  };

  useEffect(() => {
    if (authToken) {
      navigate("/");
    }
  }, [authToken, navigate]);

  const moveToLogin = () => {
    navigate("/login");
  };

  return (
    <div className="forgot-password auth">
      <div className="auth-form">
        <div className="form-inner">
          <h2>{!status ? message.heading : "Success!"}</h2>
          <h5>
            {!status ? message.text : "Password reset link send to your email."}
          </h5>
          {!status && (
            <Formik
              initialValues={initialValues}
              validationSchema={passwordValidationSchema}
              onSubmit={handlePasswordSubmit}
            >
              {({ isValid }) => (
                <Form>
                  <div>
                    <Field
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="textbox"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error"
                    />
                  </div>
                  {errorMessage && (
                    <p className="error response">{errorMessage}</p>
                  )}
                  <button type="submit">Request password reset</button>
                  <div className="action-type" onClick={moveToLogin}>
                    <p>
                      <u>Back to Log in</u>
                    </p>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
      <div
        className="auth-image"
        style={{ backgroundImage: `url(${LoginImage})` }}
      ></div>
    </div>
  );
};

export default ForgotPassword;
