import React, { useState, useEffect } from "react";
import Banner from "../components/Banner";
import Achievements from "../components/Achievements";
import Founder from "../components/Founder";
import GrappleFullAccess from "../components/GrappleFullAccess";
import GrappleLettersList from "../components/GrappleLettersList";
import BookAConsultation from "../components/BookAConsultation";
import getHomePage from "../app/services/getHomepage";

function Home() {
  const [data, setData] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const {
        data: {
          data: { attributes },
        },
      } = await getHomePage();
      setData(attributes);
    };
    fetchData();
  }, []);

  return (
    <div className="homepage">
      <Banner data={data} />
      <Achievements />
      <Founder data={data} />
      <GrappleFullAccess data={data} />
      <GrappleLettersList link="letterDetail" />
      <BookAConsultation data={data} />
    </div>
  );
}

export default Home;
