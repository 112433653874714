import React, { useState } from "react";
import { Menubar } from "primereact/menubar";
import logo from "../assets/img/ms-logo.webp";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthContext } from "../shared/AuthContext";
import { getToken } from "../shared/Helpers";
import PremiumPopup from "../components/PremiumPopup";
import LogoutPopup from "../components/LogoutPopup";

const PrivateHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuthContext();
  const [openPopup, setOpenPopup] = useState(false);
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const authToken = getToken();

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleLogout = () => {
    setShowLogoutPopup(true);
  };

  const showPremium = () => {
    setOpenPopup(true);
  };

  const onHide = () => {
    setOpenPopup(false);
  };

  const hideLogoutPopup = () => {
    setShowLogoutPopup(false);
  };

  let items = [
    {
      label: "Dashboard",
      path: "/dashboard",
      command: () => handleNavigation("/dashboard"),
      className: "menu-item-dashboard hide-on-desktop",
    },
    {
      label: "FAQs",
      path: "/faqs",
      command: () => handleNavigation("/faqs"),
      className: "menu-item-help hide-on-desktop",
    },
    {
      label: "Profile",
      command: () => handleNavigation("/profile"),
      className: "menu-item-profile hide-on-desktop",
    },
    {
      label: "Logout",
      command: handleLogout,
      className: "menu-item-logout hide-on-desktop",
    },
  ];

  if (user && !user.premium) {
    items.push({
      label: "Go premium",
      command: () => showPremium(),
      className: "button menu-item-premium hide-on-mobile",
    });
  }

  items.push({
    label: "My account",
    command: () => handleNavigation("/dashboard"),
    className: "button menu-item-login hide-on-mobile",
  });

  items.forEach((item) => {
    if (item.path && location.pathname === item.path) {
      item.className += " active";
    }
  });

  const navigateHomepage = () => {
    handleNavigation("/");
  };

  const start = (
    <div className="header-start">
      <img
        src={logo}
        alt="Logo"
        className="header-logo"
        onClick={navigateHomepage}
      />
    </div>
  );

  return (
    <>
      <div className="header">
        <Menubar className="header-component" model={items} start={start} />
      </div>
      {openPopup && (
        <PremiumPopup
          name={user.username}
          email={user.email}
          authToken={authToken}
          letterLink=""
          onHide={onHide}
        />
      )}
      {showLogoutPopup && <LogoutPopup onHide={hideLogoutPopup} />}
    </>
  );
};

export default PrivateHeader;
