import React from "react";
import Gif from "../assets/img/loader.gif";

const Loader = () => {
  return (
    <div className="loader-section">
      <img src={Gif} alt="animated-gif" />
      <p>Loading...</p>
    </div>
  );
};

export default Loader;
