import Axios from "../api/axios";
import config from "../../config";

const signup = (username, email, password) => {
  return Axios.post(`${config.BASE_URL}auth/local/register`, {
    username: username,
    email: email,
    password: password
  });
};

export default signup;
