import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import SignupImage from "../assets/img/signup-banner.svg";
import eye from "../assets/img/eye.svg";
import * as Yup from "yup";
import { setToken } from "../shared/Helpers";
import { useNavigate } from "react-router-dom";
import { getToken } from "./../shared/Helpers";
import signup from "../app/services/signup";
import sendMail from "../app/services/sendMail";

const signupValidationSchema = Yup.object().shape({
  name: Yup.string().required("Please enter your name"),
  email: Yup.string()
    .email("Invalid email")
    .required("Please enter your email address"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  termsAndConditions: Yup.bool().oneOf(
    [true],
    "You must accept the terms & conditions"
  ),
  personalUse: Yup.bool().oneOf(
    [true],
    "You must confirm this is for personal use"
  ),
});

const Signup = () => {
  const initialValues = {
    name: "",
    email: "",
    password: "",
    termsAndConditions: false,
    personalUse: false,
  };
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const authToken = getToken();

  const handleSignupSubmit = async (values) => {
    const username = values.name;
    const email = values.email;
    const password = values.password;
    try {
      const response = await signup(username, email, password);
      if (response?.error) {
        throw response?.data?.error;
      } else {
        setToken(response?.data?.jwt);
        const templateId = "d-84f24edfe8034f679cb40ed8d7934601";
        const letterText = "";
        const fileName = "";
        try {
          await sendMail(
            email,
            username,
            email,
            letterText,
            fileName,
            templateId
          );
          navigate("/dashboard");
        } catch (e) {
          console.log(e);
        }
      }
    } catch (e) {
      const error = e?.response?.data?.error?.message;
      setErrorMessage(error);
    }
  };

  useEffect(() => {
    if (authToken) {
      navigate("/dashboard");
    }
  }, [authToken, navigate]);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const moveToLogin = () => {
    navigate("/login");
  };

  return (
    <div className="signup auth">
      <div className="auth-form">
        <div className="form-inner">
          <h2>Become a Monaco Solicitors AI member for free</h2>
          <h5>Create an account to view & save your documents</h5>
          <p>
            Your document will be stored securely so you can access it anytime,
            from any device.
          </p>
          <Formik
            initialValues={initialValues}
            validationSchema={signupValidationSchema}
            onSubmit={handleSignupSubmit}
          >
            {({ isValid }) => (
              <Form>
                <div>
                  <Field
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Name"
                    className="textbox"
                  />
                  <ErrorMessage name="name" component="div" className="error" />
                </div>
                <div>
                  <Field
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    className="textbox"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                  />
                </div>
                <div>
                  <div className="password-field">
                    <Field
                      type={showPassword ? "text" : "password"}
                      id="password"
                      name="password"
                      placeholder="Password"
                      className="textbox"
                    />
                    <img
                      src={eye}
                      alt={eye}
                      width="20px"
                      onClick={handleTogglePassword}
                    />
                  </div>
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="checkbox-group">
                  <label>
                    <Field type="checkbox" name="termsAndConditions" />
                    <span>
                      I accept the <Link to="/terms">terms & conditions</Link>
                    </span>
                  </label>
                  <ErrorMessage
                    name="termsAndConditions"
                    component="div"
                    className="error"
                  />
                </div>

                <div className="checkbox-group">
                  <label>
                    <Field type="checkbox" name="personalUse" />
                    <span>I am only using this for personal use</span>
                  </label>
                  <ErrorMessage
                    name="personalUse"
                    component="div"
                    className="error"
                  />
                </div>
                {errorMessage && (
                  <p className="error response">{errorMessage}</p>
                )}
                <div className="signup-buttoms">
                  <button type="submit">Create account</button>
                  {/* <Link className="premium-button" to="/premium-signup">
                    Grapple Premium
                  </Link> */}
                </div>
                <div className="action-type" onClick={moveToLogin}>
                  <p>
                    <u>
                      Already have an account? <span>Log in</span>
                    </u>
                  </p>
                  <p>
                    Your information is kept completely private and not given to
                    anyone. By continuing you agree to the Terms and Conditions
                    of Monaco Solicitors AI premium service
                  </p>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div
        className="auth-image"
        style={{ backgroundImage: `url(${SignupImage})` }}
      ></div>
    </div>
  );
};

export default Signup;
