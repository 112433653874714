import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const UpdateLetterPopup = (props) => {
  const navigate = useNavigate();
  const updateAnswer = () => {
    navigate(`/data-inputs?letter=${props.queryParamValue}`);
  };
  return (
    <div className="action-popup update-letter">
      <h4>Update letter</h4>
      <p>
        If you wish to update your letter, this will replace the first letter.
        Are you okay with this?
      </p>
      <div className="action-popup-footer">
        <button className="button cancel-button" onClick={props.onHide}>
          No, take me back
        </button>
        <button className="button logout-button" onClick={updateAnswer}>
          Yes, replace the old letter
        </button>
      </div>
    </div>
  );
};

UpdateLetterPopup.propTypes = {
  queryParamValue: PropTypes.string,
  onHide: PropTypes.func,
};

export default UpdateLetterPopup;
