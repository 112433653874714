import Axios from "../api/axios";
import config from "../../config";

const updateUser = (token, updateData) => {
  const payload = Object.entries(updateData).reduce((acc, [key, value]) => {
    if (value !== null) {
      acc[key] = value;
    }
    return acc;
  }, {});

  return Axios.put(`${config.BASE_URL}user/me`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export default updateUser;