import Axios from "../api/axios";
import config from "../../config";

const resetPasswordService = (values) => {
  return Axios.post(`${config.BASE_URL}auth/reset-password`, {
    code: values.code,
    password: values.password,
    passwordConfirmation: values.confirmPassword,
  });
};

export default resetPasswordService;
