import Axios from "../api/axios";
import config from "../../config";

const checkAvailability = (username, email) => {
  return Axios.post(`${config.BASE_URL}check-availability`, {
    username: username,
    email: email,
  });
};

export default checkAvailability;
