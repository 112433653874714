import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { PublicRoutes, PrivateRoutes } from "./shared/RoutesList";
import PublicLayout from "./layout/publicLayout";
import PrivateLayout from "./layout/privatelayout";
import ScrollToTop from "../src/components/ScrollToTop";

const AppRoute = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        {PublicRoutes.map(({ component: Component, path }, index) => (
          <Route
            path={path}
            key={`public-${index}`}
            element={
              <PublicLayout>
                <Component />
              </PublicLayout>
            }
          ></Route>
        ))}
        {PrivateRoutes.map(({ component: Component, path }, index) => (
          <Route
            path={path}
            key={`private-${index}`}
            element={
              <PrivateLayout>
                <Component />
              </PrivateLayout>
            }
          ></Route>
        ))}
      </Routes>
    </Router>
  );
};

export default AppRoute;
