import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import sendMail from "../app/services/sendMail";
import updateUserPayment from "../app/services/updateUserPayment";
import createPayment from "../app/services/createPaymentIntend";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import payment from "../assets/img/payment.svg";
import eye from "../assets/img/eye.svg";
import checkAvailability from "../app/services/checkAvalability";
import signup from "../app/services/signup";
import { setToken } from "../shared/Helpers";

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const initialValues = {
    name: "",
    email: "",
    password: "",
    termsAndConditions: false,
    personalUse: false,
    card: true,
  };

  const [message, setMessage] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [cardComplete, setCardComplete] = useState(false);
  const [cardError, setCardError] = useState("");
  const navigate = useNavigate();

  const signupValidationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter your name"),
    email: Yup.string()
      .email("Invalid email")
      .required("Please enter your email address"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    termsAndConditions: Yup.bool().oneOf(
      [true],
      "You must accept the terms & conditions"
    ),
    personalUse: Yup.bool().oneOf(
      [true],
      "You must confirm this is for personal use"
    ),
  });

  const handleCardChange = (event) => {
    setCardComplete(event.complete);
    if (!event.complete) {
      setCardError("Card details are required");
    } else {
      setCardError("");
    }
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const moveToLogin = () => {
    navigate("/login");
  };

  const handleGAEventOnPayment = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "form",
      form: {
        action: "submit",
        id: "grapple-payment",
        name: "grapple",
        type: "payment complete",
        category: "purchase",
        success: true,
      },
    });
  };

  const handleSignupSubmit = async (values, actions) => {
    if (!cardComplete) {
      setCardError("Card details are required");
      actions.setSubmitting(false);
      return;
    }

    if (!stripe || !elements) {
      return;
    }

    try {
      await checkAvailability(values.name, values.email);
    } catch (error) {
      console.error("An error occurred:", error);
      setErrorMessage(error?.response?.data?.error?.details);
      return;
    }

    try {
      const cardElement = elements.getElement(CardElement);
      const paymentMethodResult = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: { email: values.email },
      });

      if (paymentMethodResult.error) {
        setMessage(paymentMethodResult.error.message);
        // setIsLoading(false);
        return;
      }

      const paymentMethodId = paymentMethodResult.paymentMethod.id;
      const response = await createPayment(values.email, paymentMethodId);
      if (response?.status === 200) {
        // setIsPaymentDone(true);
        const subscriptionId = response?.data?.subscriptionId;
        const timeStamp = new Date().getTime();

        try {
          // Attempt to sign up the user and handle errors.
          const response = await signup(
            values.name,
            values.email,
            values.password
          );
          if (response?.error) {
            throw new Error(response.data.error);
          }
          setToken(response.data.jwt);
          await sendMail(
            values.email,
            values.name,
            values.email,
            "",
            "",
            "d-84f24edfe8034f679cb40ed8d7934601"
          );
          await updateUserPayment(
            response.data.jwt,
            true,
            timeStamp,
            "£49.99",
            subscriptionId
          );
          await sendMail(
            values.email,
            values.name,
            values.email,
            "",
            "",
            "d-b64824820e794e0aabe5caeb9a10b90e"
          );
          handleGAEventOnPayment();
          navigate("/dashboard");
        } catch (e) {
          // Simplify error handling to directly set error message from caught exception.
          const errorMessage =
            e?.response?.data?.error?.message ||
            e.message ||
            "An unexpected error occurred.";
          setErrorMessage(errorMessage);
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setErrorMessage(error?.response?.data?.error?.message);
    }
  };

  return (
    <div className="payment-form">
      <Formik
        initialValues={initialValues}
        validationSchema={signupValidationSchema}
        onSubmit={handleSignupSubmit}
      >
        {({ isValid }) => (
          <Form id="combined-form">
            <div>
              <Field
                type="text"
                id="name"
                name="name"
                placeholder="Name"
                className="textbox"
              />
              <ErrorMessage name="name" component="div" className="error" />
            </div>
            <div>
              <Field
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                className="textbox"
              />
              <ErrorMessage name="email" component="div" className="error" />
            </div>
            <div>
              <div className="password-field">
                <Field
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Password"
                  className="textbox"
                />
                <img
                  src={eye}
                  alt="toggle visibility"
                  width="20px"
                  onClick={handleTogglePassword}
                />
              </div>
              <ErrorMessage name="password" component="div" className="error" />
            </div>
            <div className="payment-field">
              <CardElement id="payment-element" onChange={handleCardChange} />
              {cardError && <div className="error">{cardError}</div>}
              {errorMessage && (
                <div className="error response">{errorMessage}</div>
              )}
              {message && <div className="error response">{message}</div>}
            </div>
            <div className="checkbox-group">
              <label>
                <Field type="checkbox" name="termsAndConditions" />
                <span>
                  I accept the <Link to="/terms">terms & conditions</Link>
                </span>
              </label>
              <ErrorMessage
                name="termsAndConditions"
                component="div"
                className="error"
              />
            </div>

            <div className="checkbox-group">
              <label>
                <Field type="checkbox" name="personalUse" />
                <span>I am only using this for personal use</span>
              </label>
              <ErrorMessage
                name="personalUse"
                component="div"
                className="error"
              />
            </div>
            <img src={payment} alt="payment-icon" />
            <div className="signup-buttoms">
              <button
                className="premium-button"
                type="submit"
                disabled={!cardComplete}
              >
                Create account
              </button>
              {/* <Link className="continue-button premium-button" to="/signup">
                Continue for Free
              </Link> */}
            </div>
            <div className="action-type" onClick={moveToLogin}>
              <p>
                <u>
                  Already have an account? <span>Log in</span>
                </u>
              </p>
              <p>
                Your information is kept completely private and not given to
                anyone. By continuing you agree to the Terms and Conditions of
                Monaco Solicitors AI premium service
              </p>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CheckoutForm;
