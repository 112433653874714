import React from "react";

function CclComplete() {
  return (
    <>
      <div className="ccl-completed container">
        <h2>Thank you for agreeing to our Terms of Business.</h2>
        <p>
          We appreciate your prompt response and we are pleased to confirm that
          your agreement has been successfully recorded.
        </p>

        <div className="next-section">
          <h3>Whats next?</h3>
          <ul>
            <li>
              A copy of the signed agreement will be sent to your email shortly.
            </li>
            <li>
              Please check your inbox for further instructions, including a
              request to complete your timeline information.
            </li>
          </ul>
        </div>
        <p>
          If you have any questions or require further assistance, feel free to
          reach out to us.
        </p>
        <p>Thank you for your cooperation.</p>
      </div>
    </>
  );
}

export default CclComplete;
