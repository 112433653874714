import React, { useState } from "react";
import PropTypes from "prop-types";
import grappleLogo from "../assets/img/ms-logo.webp";
import { getToken } from "../shared/Helpers";
import updateUser from "../app/services/updateUser";

const UpgradePopup = ({ onHide }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const authToken = getToken();

  const handleUpgrade = async () => {
    setIsLoading(true);
    setError(null);
    const updateField = {
      premium: true,
    };
    try {
      await updateUser(authToken, updateField);
      onHide();
      window.location.reload();
    } catch (error) {
      setError("An error occurred while upgrading. Please try again.");
      console.error("An error occurred:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="upgrade action-popup">
      <img src={grappleLogo} alt="grapple-logo" width="125px" height="auto" />
      <h4>Upgrade to Premium</h4>
      <p>
        This account is currently not a premium account. Would you like to
        upgrade this account to premium?
      </p>
      {error && <p className="error-message">{error}</p>}
      <div className="action-popup-footer">
        <button
          className="button cancel-button"
          onClick={onHide}
          disabled={isLoading}
        >
          Cancel
        </button>
        <button
          className="button upgrade-button"
          onClick={handleUpgrade}
          disabled={isLoading}
        >
          {isLoading ? "Upgrading..." : "Upgrade to Premium"}
        </button>
      </div>
    </div>
  );
};

UpgradePopup.propTypes = {
  onHide: PropTypes.func.isRequired,
};

export default UpgradePopup;
