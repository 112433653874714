import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Help from "../assets/img/Help.svg";
import Home from "../assets/img/Home.svg";
import Logout from "../assets/img/logout.svg";
import Profile from "../assets/img/Person.svg";
import getUser from "../app/services/getUser";
import { getToken, removeToken } from "./../shared/Helpers";
import { useNavigate, useLocation } from "react-router-dom";
import UserContext from "./userContext";
import LogoutPopup from "../components/LogoutPopup";
import getTrackingParams from "../utils/getTrackingParams";
import updateUser from "../app/services/updateUser";

const UserLayout = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const authToken = getToken();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    setShowPopup(true);
  };

  const onHide = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    const fetchLoggedInUser = async (token) => {
      const trackingData = getTrackingParams();
      try {
        const response = await getUser(token);
        setUserData(response?.data);
        await updateUser(authToken, { trackingData: trackingData });
      } catch (error) {
        removeToken();
        console.error(error);
        navigate("/login");
      }
    };
    if (authToken) {
      fetchLoggedInUser(authToken);
    } else {
      navigate("/login");
    }
  }, [authToken, navigate]);

  const menuItem = [
    {
      id: 1,
      name: "Dashboard",
      icon: Home,
      link: "/dashboard",
    },
    {
      id: 2,
      name: "FAQs",
      icon: Help,
      link: "/faqs",
    },
    {
      id: 3,
      name: "Profile",
      icon: Profile,
      link: "/profile",
    },
    {
      id: 4,
      name: "Logout",
      icon: Logout,
      link: "/login",
    },
  ];

  return (
    <UserContext.Provider value={userData}>
      <div className="userLayout">
        <div className="sidebar">
          {menuItem.map((item) => (
            <div
              className={`menu-item ${
                location.pathname === item.link ? "active" : ""
              }`}
              key={item.id}
              onClick={() => {
                if (item.name === "Logout") {
                  handleLogout();
                } else {
                  navigate(item.link);
                }
              }}
              role="button"
              tabIndex={0}
              style={{ cursor: "pointer" }}
            >
              <img src={item.icon} alt={`${item.name} icon`} />
              {item.name}
            </div>
          ))}
        </div>
        <div className="content">{children}</div>
      </div>
      {showPopup && <LogoutPopup onHide={onHide} />}
    </UserContext.Provider>
  );
};

UserLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserLayout;
