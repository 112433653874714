/* eslint-disable react/jsx-no-target-blank */
import MSLogo from "../assets/img/ms-logo.svg";
import PropTypes from "prop-types";

const BookAConsultation = ({ data }) => {
  return (
    <div className="ms-book-consultation">
      <div className="ms-book-consultation-container">
        <div>
          <img src={MSLogo} alt="ms-logo" />
        </div>
        <div>
          <h4>{data?.MSTitle}</h4>
          <p>{data?.MSDescription}</p>
          <a href={data?.MSButtonLink} target="_blank">
            {data?.MSButtonText}
          </a>
        </div>
      </div>
    </div>
  );
};

BookAConsultation.propTypes = {
  data: PropTypes.object,
};

export default BookAConsultation;
