import React, { useEffect, useState } from "react";
import Breadcrumb from "../components/Breadcrumb";
import getUser from "../app/services/getUser";
import { getToken, removeToken } from "./../shared/Helpers";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import PremiumPopup from "../components/PremiumPopup";

function Pricing() {
  const [userData, setUserData] = useState(null);
  const authToken = getToken();
  const [openPopup, setOpenPopup] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLoggedInUser = async (token) => {
      try {
        const response = await getUser(token);
        setUserData(response?.data);
      } catch (error) {
        removeToken();
        console.error(error);
      }
    };
    if (authToken) {
      fetchLoggedInUser(authToken);
    }
  }, [authToken]);

  const onHide = () => {
    setOpenPopup(false);
  };

  const enablePremium = () => {
    if (userData) {
      setOpenPopup(true);
    } else {
      navigate("/premium-signup");
    }
  };

  return (
    <>
      <div className="pricing">
        <div className="banner">
          <Breadcrumb data="Pricing" type="page" />
          <div className="banner-container">
            <div className="banner-caption">
              <h1>Pricing</h1>
              <p>
                Monaco Solicitors AI Premium empowers you to create letters
                crucial for your case and respond to employers with confidence.
                Craft, refine, and send with ease.
              </p>
            </div>
          </div>
        </div>
        <div className="pricing-table">
          <div className="basic-plan">
            <div className="plan-header">
              <h3>
                Monaco Solicitors AI <span>Free</span>
              </h3>
              <p>£0/month</p>
            </div>
            <div className="plan-body">
              <ul>
                <li>
                  <b>Do I Have a case?: </b> - Advice note
                </li>
                <li className="free">
                  <b>Key documents in your case: </b> - Without Prejudice letter{" "}
                  <br /> - Grievance letter <br />- ET1 Claim form
                  <br />- Data Subject Access Request
                </li>
                <li className="free">
                  <b>Respond to Employer:</b>- Copy and paste the communications
                  <br />- Allow Monaco Solicitors AI to compose the reply
                  <br />- Improves your presentation to resemble that of a legal
                  professional
                </li>
                <li className="free">
                  <b>Check my Letter:</b> Making sure you included all the
                  necessary details in your letter, to boost your case
                </li>
              </ul>
              <Link to="/signup" className="button free">
                Continue for Free
              </Link>
            </div>
          </div>
          <div className="premium-plan">
            <div className="plan-header">
              <h3>
                Monaco Solicitors AI <span>Premium</span>
              </h3>
              <p>£49.99/month, Cancel anytime</p>
            </div>
            <div className="plan-body">
              <ul>
                <li>
                  <b>Do I Have a case?: </b> - Advice note
                </li>
                <li>
                  <b>Key documents in your case: </b> - Without Prejudice letter{" "}
                  <br /> - Grievance letter <br />- ET1 Claim form
                  <br />- Data Subject Access Request
                </li>
                <li>
                  <b>Respond to Employer:</b>- Copy and paste the communications
                  <br />- Allow Monaco Solicitors AI to compose the reply
                  <br />- Improves your presentation to resemble that of a legal
                  professional
                </li>
                <li>
                  <b>Check my Letter:</b> Making sure you included all the
                  necessary details in your letter, to boost your case
                </li>
              </ul>
              <div onClick={enablePremium} className="button premium">
                Get Premium Now
              </div>
            </div>
          </div>
        </div>
      </div>
      {openPopup && (
        <PremiumPopup
          name={userData.username}
          email={userData.email}
          authToken={authToken}
          letterLink="check-letter"
          onHide={onHide}
        />
      )}
    </>
  );
}

export default Pricing;
