import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import LoginImage from "../assets/img/login-banner.svg";
import eye from "../assets/img/eye.svg";
import * as Yup from "yup";
import { setToken } from "../shared/Helpers";
import login from "../app/services/login";
import { useNavigate } from "react-router-dom";
import { getToken } from "./../shared/Helpers";

const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Please enter your email address"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});

const Login = () => {
  const initialValues = {
    email: "",
    password: "",
  };
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const authToken = getToken();

  const handleLoginSubmit = async (values) => {
    const identifier = values.email;
    const password = values.password;
    try {
      const response = await login(identifier, password);
      const data = response.data;
      if (data?.error) {
        throw data?.error;
      } else {
        setToken(data.jwt);
        navigate("/dashboard");
      }
    } catch (e) {
      const error = e?.response?.data?.error?.message;
      setErrorMessage(error);
    }
  };

  useEffect(() => {
    if (authToken) {
      navigate("/dashboard");
    }
  }, [authToken, navigate]);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const moveToSignin = () => {
    navigate("/signup");
  };

  const moveToForgotPassword = () => {
    navigate("/forgot-password");
  };

  return (
    <div className="login auth">
      <div className="auth-form">
        <div className="form-inner">
          <h2>Welcome Back</h2>
          <h5>Please log in to proceed</h5>
          <Formik
            initialValues={initialValues}
            validationSchema={loginValidationSchema}
            onSubmit={handleLoginSubmit}
          >
            {({ isValid }) => (
              <Form>
                <div>
                  <Field
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    className="textbox"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                  />
                </div>
                <div>
                  <div className="password-field">
                    <Field
                      type={showPassword ? "text" : "password"}
                      id="password"
                      name="password"
                      placeholder="Password"
                      className="textbox"
                    />
                    <img
                      src={eye}
                      alt={eye}
                      width="20px"
                      onClick={handleTogglePassword}
                    />
                  </div>
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="error"
                  />
                </div>
                {errorMessage && (
                  <p className="error response">{errorMessage}</p>
                )}
                <button type="submit">Login</button>
                <div className="action-type" onClick={moveToSignin}>
                  <p>
                    Don't have an account?{" "}
                    <span>
                      <u>Create account</u>
                    </span>
                  </p>
                </div>
                <div
                  className="action-type link-text"
                  onClick={moveToForgotPassword}
                >
                  <p>
                    <u>Forgot password</u>
                  </p>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div
        className="auth-image"
        style={{ backgroundImage: `url(${LoginImage})` }}
      ></div>
    </div>
  );
};

export default Login;
