import Axios from "../api/axios";
import config from "../../config";

const getParagraphs = (statements) => {
  return Axios.get(
    `${config.BASE_URL}statements?${statements}`
  );
};

export default getParagraphs;
