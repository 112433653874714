import React, { useState, useRef } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import PropTypes from "prop-types";
import sendMail from "../app/services/sendMail";
import updateUserPayment from "../app/services/updateUserPayment";
import createPayment from "../app/services/createPaymentIntend";
import paymentImage from "../assets/img/payment.svg";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";

const PremiumPopupCheckout = (props) => {
  const stripe = useStripe();
  const toast = useRef(null);
  const elements = useElements();
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleGAEventOnPayment = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "form",
      form: {
        action: "submit",
        id: "grapple-payment",
        name: "grapple",
        type: "payment complete",
        category: "purchase",
        success: true,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    try {
      const cardElement = elements.getElement(CardElement);
      const paymentMethodResult = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: { email: props.email },
      });

      if (paymentMethodResult.error) {
        setMessage(paymentMethodResult.error.message);
        setIsLoading(false);
        return;
      }

      const paymentMethodId = paymentMethodResult.paymentMethod.id;
      const response = await createPayment(props.email, paymentMethodId);
      if (response?.status === 200) {
        const subscriptionId = response?.data?.subscriptionId;
        const element = document.querySelector("#root > .App");
        element.className = "App";
        element.classList.add("premium-user");
        const timeStamp = new Date().getTime();

        await updateUserPayment(
          props.authToken,
          true,
          timeStamp,
          "£49.99",
          subscriptionId
        );
        const templateId = "d-b64824820e794e0aabe5caeb9a10b90e";
        const letterText = "";
        const fileName = "";
        await sendMail(
          props.email,
          props.name,
          props.email,
          letterText,
          fileName,
          templateId
        );
        handleGAEventOnPayment();
        if (props.letterLink.length > 1) {
          if (props.letterLink !== "check-letter") {
            navigate(`/data-inputs?letter=${props.letterLink}`);
          } else {
            navigate("/dashboard");
          }
        } else {
          window.location.reload();
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setMessage(error?.response?.data?.error?.message);
    }

    setIsLoading(false);
  };

  return (
    <>
      <div className="popup-checkout payment-form">
        <form id="payment-form" onSubmit={handleSubmit}>
          <CardElement id="payment-element" />
          <img src={paymentImage} alt="payment-icon" />
          <button disabled={isLoading || !stripe || !elements} id="submit">
            <span id="button-text">
              {isLoading ? (
                <div className="spinner" id="spinner"></div>
              ) : (
                "Go Monaco Solicitors AI Premium"
              )}
            </span>
          </button>
          {message && <div id="payment-message">{message}</div>}
          <p>£49.99 per month, cancel any time</p>
        </form>
      </div>
      <Toast ref={toast} />
    </>
  );
};

PremiumPopupCheckout.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  authToken: PropTypes.string,
};

export default PremiumPopupCheckout;
