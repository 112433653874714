import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PremiumPopupCheckout from "../components/PremiumPopupCheckout";
import config from "../config";
import { Link } from "react-router-dom";
import PremiumCheck from "../assets/img/Premium-Check.svg";
import PropTypes from "prop-types";

function PremiumPopup(props) {
  const stripePromise = loadStripe(config.stripe_key);
  return (
    <div className="auth-popup premium">
      <div className="auth-popup-container">
        <h2>Get Full Access with Monaco Solicitors AI Premium</h2>
        <p>
          Monaco Solicitors AI Premium empowers you to create letters crucial
          for your case and respond to employers with confidence. Craft, refine,
          and send with ease.
        </p>
        <div className="premium-plan">
          <div>
            <img src={PremiumCheck} alt="premium-check" />
            <span>Monaco Solicitors AI Premium</span>
          </div>
          <h3>£49.99/month </h3>
          <p>Cancel anytime</p>
        </div>
        <Elements stripe={stripePromise}>
          <PremiumPopupCheckout
            name={props.name}
            email={props.email}
            authToken={props.authToken}
            letterLink={props.letterLink}
          />
        </Elements>
        <div className="back-button" onClick={props.onHide}>
          Close
        </div>
        <div className="footer-section">
          <p>
            Your information is kept completely private and not given to anyone.
            By continuing you agree to the Terms and Conditions of Monaco
            Solicitors AI premium service
          </p>
          <Link to="/pricing">Pricing</Link>
        </div>
      </div>
    </div>
  );
}

PremiumPopup.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  authToken: PropTypes.string,
  letterLink: PropTypes.string,
  onHide: PropTypes.func,
};

export default PremiumPopup;
