import Axios from "../api/axios";
import config from "../../config";

const getUser = (token) => {
  return Axios.get(`${config.BASE_URL}users/me`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export default getUser;
