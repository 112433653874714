import React from "react";
import grappleLogo from "../assets/img/ms-logo.webp";
import PropTypes from "prop-types";
import { useAuthContext } from "../shared/AuthContext";
import { useNavigate } from "react-router-dom";
import { removeToken } from "./../shared/Helpers";

const LogoutPopup = (props) => {
  const { setUser } = useAuthContext();
  const navigate = useNavigate();
  const handleLogout = () => {
    removeToken();
    setUser(null);
    navigate("/login");
  };
  return (
    <div className="action-popup">
      <img src={grappleLogo} alt="grapple-logo" width="125px" height="auto" />
      <h4>Log out</h4>
      <p>Are you sure that you want to log out?</p>
      <div className="action-popup-footer">
        <button className="button cancel-button" onClick={props.onHide}>Cancel</button>
        <button className="button logout-button" onClick={handleLogout}>
          Log out
        </button>
      </div>
    </div>
  );
};

LogoutPopup.propTypes = {
  onHide: PropTypes.func,
};

export default LogoutPopup;
