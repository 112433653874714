import Axios from "../api/axios";
import config from "../../config";

const grappleAi = (message, system) => {
  return Axios.post(`${config.BASE_URL}grapple-ai`, {
    message: message,
    system: system,
  });
};

export default grappleAi;
