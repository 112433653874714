import Axios from "../api/axios";
import config from "../../config";

const getAccessToken = (email) => {
  return Axios.post(`${config.BASE_URL}generate-token`, {
    email: email,
  });
};

export default getAccessToken;
