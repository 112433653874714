import Routes from "../shared/Routes";
export const ROUTES = Routes;
export const GRIEVANCE_INSTRUCTION =
  "I am an employee who has been treated badly at work. Please write a grievance letter to my employer setting out the facts and the law in support of my case. The grievance will eventually be sent to the HR department or legal department of the employer. It should contain all of the facts below, and use of all the letter template below. Try to fill in the gaps in the template in the square brackets using the facts. The style should be very formal like it was written by a top lawyer.";
export const WITHOUT_PREJUDICE_INSTRUCTION =
  "I am an employee who has been treated badly at work. Please write a without prejudice letter to my employer setting out the facts and the law in support of my case. The without prejudice will eventually be sent to the HR department or legal department of the employer. It should contain all of the facts below, and use of all the letter template below. Try to fill in the gaps in the template in the square brackets using the facts. The style should be very formal like it was written by a top lawyer. ";
export const breakpoints = {
  mobile: 640,
  mobileLandscape: 576,
  tablet: 832,
  tabletPotrait: 944,
  tabletLandscape: 1023,
  dekstopSmall: 960,
  desktopMedium: 1200,
  desktopLarge: 1500,
  desktopWide: 1800,
};
export const EMPLOYEMENT_STATUS = {
  "E": "Still employed",
  "Rd": "Resigned",
  "T": "Dismissed"
};
export const EMPLOYED_FOR = {
  "M2y": "More than two years",
  "2y": "Less than two years"
};
export const renderSection = (title, data) => (
  <>
    <p>
      <b>{title}</b>
    </p>
    <p>{data}</p>
  </>
);
export const formatIssueText = (issueCodes, issues) =>
    issueCodes
      .map(
        (issueCode) =>
          issues.find((issue) => issue.value === issueCode)?.text ||
          "Unknown Issue"
      )
      .join(", ");