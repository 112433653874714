import { Link } from "react-router-dom";
import Arrow from "../assets/img/expandmore.svg";

const Breadcrumb = ({ data, data2, type }) => {
  return (
    <ul className="breadcrumb">
      <li className="breadcrumb__item">
        <Link to="/">Home</Link>
        {type === "page" ? <img src={Arrow} alt="icon" /> : " /"}{" "}
      </li>
      <li className="breadcrumb__item">
        {data2 ? (
          <>
            <Link to="/monaco-solicitors-ai-letters">{data}</Link>
            <img src={Arrow} alt="icon" />
          </>
        ) : (
          data
        )}
      </li>
      {data2 && <li className="breadcrumb__item">{data2}</li>}
    </ul>
  );
};

export default Breadcrumb;
