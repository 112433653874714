import React from "react";
import cancelSub from "../assets/img/cancel-sub.svg";
import { useNavigate } from "react-router-dom";

function SubscriptionCancelled() {
  const navigate = useNavigate();

  const toDashboard = async () => {
    navigate("/dashboard");
  };

  return (
    <div className="subscription-cancelled">
      <div>
        <img src={cancelSub} alt="subscription cancelled" />
        <h2>We are sad to see you go :(</h2>
        <p>
          Your subscription as been cancelled and you will no longer be charged.
        </p>
        <div className="cancel-button" onClick={toDashboard}>
          Take me to Monaco Solicitors AI dashboard
        </div>
      </div>
    </div>
  );
}

export default SubscriptionCancelled;
