import Axios from "../api/axios";
import config from "../../config";

const getCardDetails = (email) => {
  return Axios.post(`${config.BASE_URL}card-details`, {
    email: email,
  });
};

export default getCardDetails;
