import Axios from "../api/axios";
import config from "../../config";

const setPasswordService = (values) => {
  return Axios.post(`${config.BASE_URL}set-password`, {
    token: values.token,
    password: values.password,
  });
};

export default setPasswordService;
