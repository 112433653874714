import React from "react";
import grappleLogo from "../assets/img/ms-logo.webp";
import PropTypes from "prop-types";
import cancelSubscription from "../app/services/cancelSubscription";
import updateUser from "../app/services/updateUser";
import { getToken } from "./../shared/Helpers";
import { useNavigate } from "react-router-dom";

const CancelSubscriptionPopup = (props) => {
  const authToken = getToken();
  const navigate = useNavigate();
  const handleSubmit = async () => {
    const updateField = {
      premium: false,
      subscriptionId: "",
    };
    try {
      await cancelSubscription(props.email);
      await updateUser(authToken, updateField);
      navigate("/subscription-cancelled");
    } catch (error) {
      console.error("An error occurred:", error);
      return;
    }
  };
  return (
    <div className="action-popup cancel">
      <img src={grappleLogo} alt="grapple-logo" width="125px" height="auto" />
      <h4>Cancel Subscription</h4>
      <p>
        Are you sure that you want to cancel your Monaco Solicitors AI Premium
        subscription?
      </p>
      <div className="action-popup-footer">
        <button className="button cancel-button" onClick={props.onHide}>
          Cancel
        </button>
        <button className="button logout-button" onClick={handleSubmit}>
          Yes, I am sure
        </button>
      </div>
    </div>
  );
};

CancelSubscriptionPopup.propTypes = {
  onHide: PropTypes.func,
  email: PropTypes.string,
};

export default CancelSubscriptionPopup;
