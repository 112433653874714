import React from "react";
import GrappleLettersList from "../components/GrappleLettersList";

function ChooseLetter() {
  return (
    <div className="choose-letter">
      <div className="choose-letter-container">
        <h3>Monaco Solicitors AI documents</h3>
        <p>Choose how we can help you below:</p>
      </div>
      <GrappleLettersList link="chooseLetter" />
    </div>
  );
}

export default ChooseLetter;
