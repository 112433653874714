import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import GrappleLettersList from "../components/GrappleLettersList";
import BookAConsultation from "../components/BookAConsultation";
import Breadcrumb from "../components/Breadcrumb";
import getGrappleLetters from "../app/services/getGrappleLetters";

function GrappleLetters() {
  const [data, setData] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      const {
        data: {
          data: { attributes },
        },
      } = await getGrappleLetters();
      setData(attributes);
    };
    fetchData();
  }, []);

  return (
    <div className="grapple-letters">
      <div className="banner">
        <Breadcrumb data="MSAI Letters" type="page" />
        <div className="banner-container">
          <div className="banner-caption">
            {data?.BannerHeading && <h1>{data?.BannerHeading}</h1>}
            {data?.BannerDescription && <p>{data?.BannerDescription}</p>}
            {data?.BannerList && (
              <ReactMarkdown>{data?.BannerList}</ReactMarkdown>
            )}
            {data?.BannerButtonText && (
              <Link className="button get-started" to="/signup">
                {data?.BannerButtonText}
              </Link>
            )}
          </div>
        </div>
      </div>
      <GrappleLettersList link="letterDetail" />
      <BookAConsultation data={data} />
    </div>
  );
}

export default GrappleLetters;
