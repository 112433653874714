import Axios from "../api/axios";
import config from "../../config";

const updatePassword = (token, oldPassword, newPassword) => {
  return Axios.post(
    `${config.BASE_URL}auth/change-password`,
    {
      currentPassword: oldPassword,
      password: newPassword,
      passwordConfirmation: newPassword,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

export default updatePassword;
