import config from "../config";

export const fetchAICompletion = async (
  prompt,
  letterName,
  loginType,
  letterType,
  email,
  setAnswer,
  onCompletion
) => {
  // getting response from server based on the user prompt
  const response = await fetch(`${config.BASE_URL}grapple-ai-stream`, {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${config.token}`,
    },
    body: JSON.stringify({
      message: prompt,
      letterName: letterName,
      loginType: loginType,
      letterType: letterType,
      email: email,
    }),
  });

  if (!response.ok || !response.body) {
    throw response.statusText;
  }

  // Here we start prepping for the streaming response
  const reader = response.body.getReader();
  const decoder = new TextDecoder();
  const loopRunner = true;

  while (loopRunner) {
    // Here we start reading the stream, until its done.
    const { value, done } = await reader.read();
    if (done) {
      onCompletion(); // Notify that streaming is complete
      break;
    }
    const decodedChunk = decoder.decode(value, { stream: true });
    setAnswer(decodedChunk); // update state with new chunk
  }
};
