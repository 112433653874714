import Axios from "../api/axios";
import config from "../../config";

const getStatements = (empolymentStatus, combinedIssues, combinedTopics) => {
  return Axios.post(`${config.BASE_URL}filter-statement`, {
    employmentType: empolymentStatus,
    issues: combinedIssues,
    topicsExcept: combinedTopics
  });
};

export default getStatements;
