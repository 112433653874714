import React from "react";

function Thankyou() {

  return (
    <>
      <div className="thank-you">
        <h2>Thank You for Submitting!</h2>
        <p>We have received your request and will get back to you shortly</p>
      </div>
    </>
  );
}

export default Thankyou;
