const setTrackingParams = (query) => {
  if (typeof window !== "undefined") {
    const grappleTrackingParams = JSON.parse(
      localStorage.getItem("grapple_tracking_params")
    );

    if (!grappleTrackingParams) {
      const sanitizer = new RegExp(/(^.*)?\?/);
      const regexForLandingPage = new RegExp(/\?(.*)/);
      const landingPage = query.replace(regexForLandingPage, "");

      const sanitizedQueryString = query.replace(sanitizer, "");
      const regex = new RegExp(/utm_(\S+)|(gclid)/);

      const paramsArray = sanitizedQueryString
        .split("&")
        .filter((key) => key.match(regex))
        .map((param) => {
          const keyValue = param.split("=");
          return {
            key: keyValue[0],
            value: keyValue[1],
          };
        });

      paramsArray.push({ key: "landing-page", value: landingPage });

      localStorage.setItem(
        "grapple_tracking_params",
        JSON.stringify(paramsArray)
      );
    }
  }
};

export default setTrackingParams;
