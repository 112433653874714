import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import { fetchAICompletion } from "../shared/UseGrappleStream";
import updateUser from "../app/services/updateUser";
import { getToken, getDealId, getLoginType } from "./../shared/Helpers";
// import YoutubeEmbed from "../components/YoutubeEmbed";
import GrappleLettersList from "../components/GrappleLettersList";
import { useNavigate } from "react-router-dom";
import sendMail from "../app/services/sendMail";
import getUser from "../app/services/getUser";
import ThankYouModal from "../components/ThankYouModal";
import RecommendedLetters from "./RecommendedLetters";
import EmailIcon from "../assets/img/Email.svg";
// import CheckLetterIcon from "../assets/img/check-letter.svg";
import PremiumPopup from "../components/PremiumPopup";
import SignUpPopup from "../components/SignupPopup";
import SignUpPremiumPopup from "./SignupPremiumPopup";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import config from "../config";
import EmailModal from "../components/EmailModal";

function FinalLetter({
  isUserLoggedin,
  letterName,
  letterTitle,
  letterInfo,
  adviceNote,
  userInput,
  paragraphs,
  dataInputs,
  email,
}) {
  const [finalLetter, setFinalLetter] = useState("");
  const [error, setError] = useState("");
  const [mainContent, setMainContent] = useState("");
  const [userData, setUserData] = useState({});
  const [letterLink, setLetterLink] = useState("");
  const [isStreamComplete, setIsStreamComplete] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [openAuthPopup, setOpenAuthPopup] = useState(false);
  const [openPremiumAuthPopup, setOpenPremiumAuthPopup] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [authPopupType, setAuthPopupType] = useState("");
  const authToken = getToken();
  const navigate = useNavigate();
  const localDealId = getDealId();
  const stripePromise = loadStripe(config.stripe_key);
  let loginType = getLoginType();
  const loginUserType = getLoginType();
  if (
    (letterName === "dsar-letter" &&
      loginType === "lawyer" &&
      dataInputs.letter_type === "lawyer") ||
    (letterName === "wp-letter" && loginType === "lawyer")
  ) {
    loginType = "lawyer";
  } else {
    loginType = null;
  }

  useEffect(() => {
    const updateSurveyValue = (newChunk) => {
      setMainContent((prevMainContent) => prevMainContent + newChunk);
      setFinalLetter((prevValue) => {
        const headerText =
          letterName !== "response-letter" &&
          prevValue === "" &&
          letterInfo.HeaderText
            ? letterInfo.HeaderText
            : "";
        return headerText + prevValue + newChunk;
      });
    };

    const handleCompletion = () => {
      setFinalLetter((prevValue) => {
        const footerText =
          letterName !== "response-letter" && letterInfo.FooterText
            ? letterInfo.FooterText
            : "";
        return prevValue + footerText;
      });
      setIsStreamComplete(true);
    };

    const processfinalLetter = async () => {
      const letterType = "normal";
      let initialMessage = "";
      if (letterName === "response-letter") {
        initialMessage = `${letterInfo.PromptText} \n\n Comments from me about my employer's letter: ${userInput} \n\n Original letter from me to my employer: ${letterInfo.letterTo} \n\n Letter from my employer in response to my letter: ${letterInfo.letterFrom}`;
      } else if (letterName === "advice-note") {
        initialMessage = `Summary: ${userInput}\n\nParagraphs: ${paragraphs}\n\nAdvice note: ${adviceNote}`;
      } else if (letterName === "wp-letter") {
        initialMessage = `Summary: ${userInput}\n\n${paragraphs}`;
      } else {
        initialMessage = `Summary: ${userInput}\n\nParagraphs: ${paragraphs}`;
      }
      try {
        await fetchAICompletion(
          initialMessage,
          letterName,
          loginType,
          letterType,
          email,
          updateSurveyValue,
          handleCompletion
        );
      } catch (e) {
        console.error(e);
        setError("An error occurred while processing the letter.");
      }
    };

    processfinalLetter();
  }, [
    adviceNote,
    authToken,
    isUserLoggedin,
    letterInfo.FooterText,
    letterInfo.HeaderText,
    letterInfo.LawyerPromptText,
    letterInfo.PromptText,
    letterInfo.letterFrom,
    letterInfo.letterTo,
    letterName,
    loginType,
    paragraphs,
    userInput,
  ]);

  const handleEmailModal = () => {
    setShowEmailModal(!showEmailModal);
  };

  const sendToMail = async () => {
    if (isUserLoggedin && authToken) {
      let letterText = "";
      let templateId = "";
      let fileName = "";
      const paidConUrl = localDealId
        ? `https://www.monacosolicitors.co.uk/book-your-consultation?id=${localDealId}`
        : "https://www.monacosolicitors.co.uk/book-your-paid-consultation";
      if (letterName === "grievance-letter") {
        letterText = finalLetter;
        templateId = "d-222a8db1fd4e4c7b97577ab8f4a4633d";
        fileName = "monaco_ai_gr_letter";
      } else if (letterName === "wp-letter") {
        letterText = finalLetter;
        templateId = "d-5e8d1925c2bb4c969469369aa79ee7d0";
        fileName = "monaco_ai_wp_letter";
      } else if (letterName === "et1-letter") {
        letterText = finalLetter;
        templateId = "d-5820f0f1298f4ac3ade17f39897ef7db";
        fileName = "monaco_ai_et1";
      } else if (letterName === "dsar-letter") {
        letterText = finalLetter;
        templateId = "d-99f15745ec174eea8bc33647bf251f40";
        fileName = "monaco_ai_dsar";
      } else if (letterName === "response-letter") {
        letterText = finalLetter;
        templateId = "d-e666093303644c8fa06882db37396f94";
        fileName = "monaco_ai_response";
      } else if (letterName === "witness-statement-letter") {
        letterText = finalLetter;
        templateId = "d-2b89388849d5456483e44d36b35b02b6";
        fileName = "monaco_ai_witness_statement";
      } else {
        letterText = finalLetter;
        templateId = "d-a3433582fd424844814ed90c4c5b4d2c";
        fileName = "advice-note";
      }
      if (loginUserType === "lawyer") {
        setShowModal(!showModal);
      } else {
        try {
          const response = await getUser(authToken);
          const userData = response.data;
          await sendMail(
            userData?.email,
            userData?.username,
            userData?.email,
            letterText,
            fileName,
            templateId,
            paidConUrl
          );
        } catch (error) {
          console.error(error);
        } finally {
          setShowEmailModal(true);
        }
      }
    } else {
      setOpenAuthPopup(true);
      setAuthPopupType("email");
    }
  };

  const checkYourLetter = async () => {
    if (isUserLoggedin && authToken) {
      try {
        const response = await getUser(authToken);
        const userData = response.data;
        setUserData(userData);
        if (userData.premium) {
          navigate("/check-letter", {
            state: {
              letterName: letterName,
              letterTitle: letterTitle,
              letterInfo: letterInfo,
              mainContent: mainContent,
              userData: userData,
            },
          });
        } else {
          setOpenPopup(true);
          setLetterLink("check-letter");
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      setOpenPremiumAuthPopup(true);
    }
  };

  const onHide = () => {
    setOpenPopup(false);
    setOpenAuthPopup(false);
    setOpenPremiumAuthPopup(false);
  };

  // const youtubeEmbedIds = {
  //   "advice-note": "Eyzj9Ofh0GI",
  //   "grievance-letter": "E46KLxeh9h4",
  //   "wp-letter": "4hF_OsyD0Ds",
  //   "et1-letter": "q1G3tVsCuf8",
  //   "dsar-letter": "KmKXKMJvMhc",
  //   "response-letter": "Eyzj9Ofh0GI",
  //   "witness-statement-letter": "Eyzj9Ofh0GI",
  // };

  // const embedId = youtubeEmbedIds[letterName] || "Eyzj9Ofh0GI";

  const saveLetter = async () => {
    if (isUserLoggedin && authToken) {
      let updateField = {};
      const timeStamp = new Date().getTime();
      switch (letterName) {
        case "advice-note":
          updateField = {
            isAdviceNoteGenerated: true,
            adviceNote: finalLetter,
            adviceNoteSetOn: timeStamp,
          };
          break;
        case "wp-letter":
          updateField = {
            isWpLetterGenerated: true,
            wpLetter: finalLetter,
            wpLetterSetOn: timeStamp,
            wpLetterBody: mainContent,
          };
          break;
        case "grievance-letter":
          updateField = {
            isGrievanceLetterGenerated: true,
            grievanceLetter: finalLetter,
            grievanceLetterSetOn: timeStamp,
            grievanceLetterBody: mainContent,
          };
          break;
        case "et1-letter":
          updateField = {
            isEt1LetterGenerated: true,
            et1Letter: finalLetter,
            et1LetterSetOn: timeStamp,
            et1LetterBody: mainContent,
          };
          break;
        case "witness-statement-letter":
          updateField = {
            isWitnessStatementLetterGenerated: true,
            witnessStatementLetter: finalLetter,
            witnessStatementLetterSetOn: timeStamp,
            witnessStatementLetterBody: mainContent,
          };
          break;
        case "dsar-letter":
          updateField = {
            isDsarLetterGenerated: true,
            dsarLetter: finalLetter,
            dsarLetterSetOn: timeStamp,
            dsarLetterBody: mainContent,
          };
          break;
        case "response-letter":
          updateField = {
            isRespondToEmployerLetterGenerated: true,
            respondToEmployer: finalLetter,
            respondToEmployerSetOn: timeStamp,
          };
          break;
        default:
          console.log("Letter type does not match any update criteria.");
          return;
      }
      try {
        await updateUser(authToken, updateField);
        navigate("/dashboard");
      } catch (e) {
        console.error(
          "An error occurred while updating the user and letter: ",
          e
        );
        setError("An error occurred while updating.");
      }
    } else {
      setOpenAuthPopup(true);
      setAuthPopupType("signup");
    }
  };

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <>
      <div className="letter-content-container">
        <h5>{letterTitle}</h5>
        <div
          id="content"
          className={`letter-content ${
            !isUserLoggedin ? "guest" : "logged-in"
          }`}
        >
          <ReactMarkdown>{finalLetter}</ReactMarkdown>
        </div>
        {isStreamComplete && (
          <div className="letter-actions">
            {/* {letterName !== "response-letter" &&
              letterName !== "advice-note" && (
                <div className="check-letter" onClick={checkYourLetter}>
                  <img src={CheckLetterIcon} alt="check-letter-icon" /> Check
                  Letter
                </div>
              )} */}
            <div className="send-email" onClick={sendToMail}>
              <img src={EmailIcon} alt="email-icon" /> Send to email
            </div>
          </div>
        )}
      </div>
      <div className="letter-sidebar">
        <h5>We recommend</h5>
        {letterName !== "response-letter" && (
          <RecommendedLetters
            letterName={letterName}
            isUserLoggedin={isUserLoggedin}
          />
        )}
        {isStreamComplete && (
          <div className="save-button" onClick={saveLetter}>
            Continue
          </div>
        )}
        <div className="other-letters">
          <h5>Other letters</h5>
          <GrappleLettersList link={`letterpage?letterName=${letterName}`} />
        </div>
      </div>
      {showEmailModal && (
        <ThankYouModal
          handleModal={handleEmailModal}
          visible={showEmailModal}
        />
      )}
      {openPopup && (
        <PremiumPopup
          name={userData.username}
          email={userData.email}
          authToken={authToken}
          letterLink={letterLink}
          onHide={onHide}
        />
      )}
      {openAuthPopup && (
        <SignUpPopup
          letterName={letterName}
          mainContent={mainContent}
          finalLetter={finalLetter}
          authPopupType={authPopupType}
          onHide={onHide}
        />
      )}
      {openPremiumAuthPopup && (
        <Elements stripe={stripePromise}>
          <SignUpPremiumPopup
            letterName={letterName}
            letterTitle={letterTitle}
            letterInfo={letterInfo}
            mainContent={mainContent}
            onHide={onHide}
          />
        </Elements>
      )}
      {showModal && (
        <EmailModal
          handleEmailModal={sendToMail}
          visible={showModal}
          letterName={letterName}
          letterData={finalLetter}
        />
      )}
    </>
  );
}

FinalLetter.propTypes = {
  isUserLoggedin: PropTypes.bool.isRequired,
  letterName: PropTypes.string.isRequired,
  letterTitle: PropTypes.string.isRequired,
  letterInfo: PropTypes.shape({
    PromptText: PropTypes.string,
    HeaderText: PropTypes.string,
    FooterText: PropTypes.string,
  }),
  userInput: PropTypes.string.isRequired,
  paragraphs: PropTypes.string.isRequired,
  dataInputs: PropTypes.object.isRequired,
};

export default FinalLetter;
