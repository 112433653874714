import Axios from "../api/axios";
import config from "../../config";

const getTemplateLetters = (empolymentStatus, combinedIssues) => {
  return Axios.post(`${config.BASE_URL}filter-template-letters`, {
    employmentType: empolymentStatus,
    issues: combinedIssues,
  });
};

export default getTemplateLetters;
