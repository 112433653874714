import HomePremium from "../assets/img/home-premium.svg";
import RespondeToEmployer from "../assets/img/responde-to-employer.svg";
import LetterCheck from "../assets/img/letter-check.svg";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";

const GrappleFullAccess = ({ data }) => {
  return (
    <div className="grapple-full-access">
      <div className="grapple-full-access-container">
        <div>
          <img src={HomePremium} alt="home-premium" />
        </div>
        <div>
          <h2>{data?.PremiumTitle}</h2>
          <img src={RespondeToEmployer} alt="letter-icon" />
          <ReactMarkdown>{data?.Premium1Text}</ReactMarkdown>
          <img src={LetterCheck} alt="letter-icon" />
          <ReactMarkdown>{data?.Premium2Text}</ReactMarkdown>
          <Link className="button read-more" to="/pricing">
            {data?.PremiumButtonText}
          </Link>
        </div>
      </div>
    </div>
  );
};

GrappleFullAccess.propTypes = {
  data: PropTypes.object,
};

export default GrappleFullAccess;
