import awards from "../assets/img/awards.svg";
import bbc from "../assets/img/bbc.svg";

const Achievements = () => {
  return (
    <div className="achievements">
      <div>
        <p>Award-winning Technology</p>
        <img src={awards} alt="awards" />
      </div>
      <div>
        <p>Featured on BBC</p>
        <img src={bbc} alt="awards" />
        <a
          href="https://www.bbc.com/news/business-67121212"
          target="_blank"
          rel="noopener noreferrer"
        >
          Read the full article
        </a>
      </div>
    </div>
  );
};

export default Achievements;
