import Axios from "../api/axios";
import config from "../../config";

const login = (identifier, password) => {
  return Axios.post(`${config.BASE_URL}auth/local`, {
    identifier: identifier,
    password: password
  });
};

export default login;
