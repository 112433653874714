import Axios from "../api/axios";
import config from "../../config";

const cancelSubscription = (email) => {
  return Axios.post(`${config.BASE_URL}cancel-subscription`, {
    email: email,
  });
};

export default cancelSubscription;
