import React, {
  useState,
  useRef,
  useCallback,
  useContext,
  useEffect,
} from "react";
import Breadcrumb from "../components/Breadcrumb";
import updatePasswordService from "../app/services/updatePassword";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Toast } from "primereact/toast";
import * as Yup from "yup";
import eyeIcon from "../assets/img/eye.svg";
import cardIcon from "../assets/img/card-icon.svg";
import { getToken } from "./../shared/Helpers";
import UserContext from "./../layout/userContext";
import CancelSubscriptionPopup from "../components/CancelSubscriptionPopup";
import getCardDetails from "../app/services/getCardDetails";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import UpdatePremium from "../components/UpdatePremium";
import config from "../config";

const passwordValidationSchema = Yup.object({
  oldPassword: Yup.string().required("Old Password is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Please confirm your password"),
});

const Profile = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [cardDetails, setCardDetails] = useState({});
  const toastRef = useRef(null);
  const token = getToken();
  const userData = useContext(UserContext);
  const handleTogglePassword = useCallback(() => {
    setShowPassword((prevState) => !prevState);
  }, []);
  const stripePromise = loadStripe(config.stripe_key);
  const openPopup = () => {
    setShowPopup(true);
  };

  const close = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    const fetchCardDetails = async () => {
      const {
        data: {
          cardDetails: { card },
        },
      } = await getCardDetails(userData?.email);
      setCardDetails(card);
    };
    if (userData?.subscriptionId && userData?.premium) {
      fetchCardDetails();
    }
  }, [userData?.email, userData?.premium, userData?.subscriptionId]);

  const handleSubmit = useCallback(
    async (values, { resetForm }) => {
      try {
        const response = await updatePasswordService(
          token,
          values.oldPassword,
          values.password,
          values.confirmPassword
        );
        if (response.status === 200) {
          toastRef.current.show({
            severity: "success",
            summary: "Success",
            detail: "Password changed successfully.",
            life: 5000,
          });
          resetForm();
        }
      } catch (error) {
        const errorMessage =
          error?.response?.data?.error?.message ||
          "Something went wrong, please try again.";
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: errorMessage,
          life: 5000,
        });
      }
    },
    [token]
  );

  return (
    <>
      <div className="profile">
        <Breadcrumb data="Profile" type="dashboard" />
        <h1>Profile</h1>
        <div className="profile-inner">
          <div className="personal-info">
            <h2>Personal information</h2>
            <p className="profile-field">{userData?.name ?? userData?.username}</p>
            <p className="profile-field">{userData && userData.email}</p>
          </div>
          {userData?.subscriptionId && userData?.premium && (
            <div className="payment-details">
              <h2>Payment information</h2>
              <div className="card-detail">
                <div>
                  <img src={cardIcon} alt="card" />
                  <p>
                    **** **** **** <span>{cardDetails?.last4}</span>
                  </p>
                </div>
                <p>
                  {cardDetails?.exp_month} / {cardDetails?.exp_year % 100}
                </p>
              </div>
              <Elements stripe={stripePromise}>
                <UpdatePremium
                  name={userData.username}
                  email={userData.email}
                  authToken={token}
                />
              </Elements>
              <div className="cancel-button button" onClick={openPopup}>
                Cancel subscription
              </div>
            </div>
          )}
          <div className="password">
            <h2>Password</h2>
            <Formik
              initialValues={{
                oldPassword: "",
                password: "",
                confirmPassword: "",
              }}
              validationSchema={passwordValidationSchema}
              onSubmit={handleSubmit}
            >
              {({ isValid }) => (
                <Form className="forgotPassword">
                  <div className="password-field">
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="oldPassword"
                      placeholder="Old password"
                    />
                    <img
                      onClick={handleTogglePassword}
                      src={eyeIcon}
                      alt="Toggle visibility"
                      width="20px"
                    />
                    <ErrorMessage
                      name="oldPassword"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="password-field">
                    <Field
                      type={showPassword ? "text" : "password"}
                      id="password"
                      name="password"
                      placeholder="New password"
                      className="textbox"
                    />
                    <img
                      onClick={handleTogglePassword}
                      src={eyeIcon}
                      alt="Toggle visibility"
                      width="20px"
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="password-field">
                    <Field
                      type={showPassword ? "text" : "password"}
                      id="confirmPassword"
                      name="confirmPassword"
                      placeholder="New password again"
                      className="textbox"
                    />
                    <img
                      onClick={handleTogglePassword}
                      src={eyeIcon}
                      alt="Toggle visibility"
                      width="20px"
                    />
                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      className="error"
                    />
                  </div>
                  <button
                    type="submit"
                    disabled={!isValid}
                    className="submit-button"
                  >
                    Save
                  </button>
                </Form>
              )}
            </Formik>
            <Toast ref={toastRef} />
          </div>
        </div>
      </div>
      {showPopup && (
        <CancelSubscriptionPopup onHide={close} email={userData?.email} />
      )}
    </>
  );
};

export default Profile;
