import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import { useAuthContext } from "../shared/AuthContext";

const Banner = ({ data }) => {
  const { user } = useAuthContext();
  const isLoggedIn = !!user;

  return (
    <div className="banner">
      <div className="banner-container">
        <div className="banner-caption">
          {data?.BannerHeading && <h1>{data?.BannerHeading}</h1>}
          {data?.BannerDescription && <p>{data?.BannerDescription}</p>}
          {data?.BannerList && (
            <ReactMarkdown>{data?.BannerList}</ReactMarkdown>
          )}
          {data?.BannerButtonText && (
            <Link
              className="button get-started"
              to={isLoggedIn ? "/dashboard" : "/choose-letter"}
            >
              {isLoggedIn ? "Go to dashboard" : data?.BannerButtonText}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

Banner.propTypes = {
  data: PropTypes.object,
};

export default Banner;
